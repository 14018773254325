import React from "react";
import { Skeleton } from "antd";

const CarItemSkeleton = () => {
    return (
        <li className="list-none">
            <div className="bg-white border border-gray-200  w-full rounded-lg overflow-hidden hover:shadow-md transition-shadow">
                {/* Image Skeleton */}
                <div className="w-full">
                    <Skeleton.Node
                        className="custom-skeleton-img"
                        style={{ height: "240px", borderRadius: "4px" }}
                        active
                    >
                        <div className="flex h-full items-center justify-center w-full">
                        <img
                            src={'assets/icons/tt-logo.svg'}
                            alt="fuel-icon"
                            className="w-[150px] opacity-20"
                        />
                        </div>
                    </Skeleton.Node>
                </div>
                {/* Info Skeleton */}
                <div className="px-3 py-3">
                    {/* Title and brand area */}
                    <Skeleton
                        active
                        paragraph={{ rows: 2, width: ["80%", "40%"] }}
                        title={false}
                    />
                    {/* Price and bid information */}
                    <Skeleton
                        active
                        paragraph={{ rows: 1, width: "60%" }}
                        title={false}
                    />
                </div>
            </div>
        </li>
    );
};

export default CarItemSkeleton;
