import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";

/**
 * A simple histogram-like bar chart using random data.
 */
const SmallHistogram = () => {
  // Generate random data (e.g. 12 bars)
  const data = Array.from({ length: 12 }, (_, i) => ({
    name: `Bin ${i + 1}`,
    value: Math.floor(Math.random() * 50 + 1), // bar height range: 1..50
  }));

  return (
    <div style={{ width: "100%", height: 80 }}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart data={data} margin={{ top: 5, right: 5, left: 5, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          {/* Hide axes for a minimal look */}
          <XAxis dataKey="name" hide />
          <YAxis hide />
          <Tooltip cursor={{ fill: "transparent" }} />
          <Bar dataKey="value" fill="#FFEB00" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default SmallHistogram;
