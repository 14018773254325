import { ConfigProvider } from 'antd';
import trTR from 'antd/locale/tr_TR';
import { Routes, Route } from 'react-router-dom';
import Home from './views/Home/Home';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import About from './views/About/About';
import Contact from './views/Contact/Contact';
import Auctions from './views/Auctions/Auctions';
import Profile from './views/Profile/Profile';
import Blog from './views/Blog/Blog';
import AuctionsDetail from './views/Auctions/AuctionsDetail';
import ReceivedDetail from './views/Profile/ReceivedDetail';
import BlogDetail from './views/BlogDetail/BlogDetail';
import ScrollToTop from './hooks/ScrollToTop';
import Privacy from './views/Sozlesme/Privacy';
import Account from './views/Account/Account';

function App() {
  return (
    <ConfigProvider locale={trTR} theme={{ token: { colorPrimary: '#FFEB00' } }}>
      <ScrollToTop />
      <Navbar />
      <main className='py-8 px-6'>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/hakkimizda" element={<About />} />
          <Route path="/iletisim" element={<Contact />} />
          <Route path="/ihaleler" element={<Auctions />} />
          <Route path="/ihaleler/:id" element={<AuctionsDetail />} />
          <Route path="/profilim" element={<Profile />} />
          <Route path="/hesabim" element={<Account />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:slug" element={<BlogDetail />} />
          <Route path="/received/:id" element={<ReceivedDetail />} />
          <Route path="/sozlesmeler/:id" element={<Privacy />} />
        </Routes>
      </main>
      <Footer />
    </ConfigProvider>
  );
}

export default App;
