import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Image } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { formatCurrency } from "../../utils/FormatCurrency";
import { imageBaseUrl } from "../../api/apiController";

const CarItem = ({ car }) => {
  const images = car.car?.images || [];
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const selectedImage = images[selectedImageIndex];

  const handlePrevImage = (e) => {
    e.stopPropagation(); // Prevent click event from bubbling up
    if (selectedImageIndex > 0) {
      setSelectedImageIndex(selectedImageIndex - 1);
    }
  };

  const handleNextImage = (e) => {
    e.stopPropagation(); // Prevent click event from bubbling up
    if (selectedImageIndex < images.length - 1) {
      setSelectedImageIndex(selectedImageIndex + 1);
    }
  };


  return (
    <li className="list-none">
      <div className="bg-white border border-gray-200 rounded-lg overflow-hidden hover:shadow-md transition-shadow">
        <div className="relative group">
          {selectedImage && (
            <Image
              src={`${imageBaseUrl}${selectedImage.img_path}`}
              alt="Selected Car Image"
              className="object-cover w-full h-[320px] rounded-[4px] cursor-pointer"
              preview={{ src: `${imageBaseUrl}${selectedImage.img_path}` }}
            />
          )}

          {selectedImageIndex > 0 && (
            <div
              onClick={handlePrevImage}
              className="absolute left-4 top-1/2 transform -translate-y-1/2 z-10 cursor-pointer opacity-0 group-hover:opacity-100 transition-opacity duration-300"
            >
              <LeftOutlined className="text-white text-2xl bg-gray-700 bg-opacity-50 p-2 rounded-full" />
            </div>
          )}

          {selectedImageIndex < images.length - 1 && (
            <div
              onClick={handleNextImage}
              className="absolute right-4 top-1/2 transform -translate-y-1/2 z-10 cursor-pointer opacity-0 group-hover:opacity-100 transition-opacity duration-300"
            >
              <RightOutlined className="text-white text-2xl bg-gray-700 bg-opacity-50 p-2 rounded-full" />
            </div>
          )}
        </div>

        {/* Car Info wrapped in Link */}
        <Link to={`/ihaleler/${car.id}`} className="block px-3 py-3">
          <div className="flex justify-between items-start w-full">
            <div className="flex flex-col w-full">
              <div className="flex flex-row gap-1">
              <h2 className="font-semibold text-[#09090B] text-lg">
                {car.title}
              </h2>
              <img
              src={imageBaseUrl + car?.car?.car_brand?.img_path}
              alt={`${car.name} Logo`}
              className="w-[32px] h-[32px]"
            />
            </div>

              <div className="grid grid-cols-2 gap-2 w-full">
                <div className="w-full bg-zinc-100 gap-2 px-1 py-1 rounded-[4px] flex flex-row items-center justify-center text-sm text-[#71717A]">
                  <img
                    src={'assets/icons/km.svg'}
                    alt="fuel-icon"
                    className="w-5 h-5"
                  />
                  <span className="text-black">{car.car?.mileage} KM</span>
                </div>
                <div className="w-full bg-zinc-100 gap-2 px-1 py-1 rounded-[4px]  flex flex-row items-center justify-center text-sm text-[#71717A]">
                  <img
                    src={imageBaseUrl + car?.car?.car_fuel_type?.img_path}
                    alt="fuel-icon"
                    className="w-5 h-5"
                  />
                  <span className="text-black">{car?.car?.car_fuel_type?.name}</span>
                </div>
                <div className="w-full  bg-zinc-100 gap-2 px-1 py-1 rounded-[4px]  flex flex-row items-center justify-center  text-sm text-[#71717A]">
                  <img
                    src={imageBaseUrl + car?.car?.car_gear_type?.img_path}
                    alt="gear-icon"
                    className="w-5 h-5"
                  />
                  <span className="text-black">{car?.car?.car_gear_type?.name}</span>
                </div>
                <div className="w-full  bg-zinc-100 gap-2 px-1 py-1 rounded-[4px]   flex flex-row items-center justify-center text-sm text-[#71717A]">
                  <img
                    src="/assets/icons/calendar-colourless.svg"
                    alt="calendar-icon"
                    className="w-5 h-5"
                  />
                  <span className="text-black">{car.car.year}</span>
                </div>
              </div>
            </div>
          
          </div>

          <div className="border-t-2 border-dashed border-gray-200 w-full my-4"></div>

          <div className="flex flex-col">
            <div className="flex flex-col">
              <h6 className="text-sm font-light text-[#71717A]">
                Açılış Fiyatı
              </h6>
              <div className="flex items-center gap-2.5">
                <span className="font-bold text-[#09090B] text-[20px]">
                  {formatCurrency(car.starting_price)}
                </span>
                <span className="bg-[#FFEB00] py-[6px] px-[12px] rounded-[8px] text-[#09090B] font-medium text-sm">
                  Açık Artırma
                </span>
              </div>
            </div>
            <div className="flex items-center gap-2 mt-3">
              <span className="bg-zinc-100 py-[6px] px-[12px] rounded-[8px] text-[#09090B] font-medium text-sm">
                Otomatik Teklif
              </span>
              <span className="bg-[#FFEB00] py-[6px] px-[12px] rounded-[8px] text-[#09090B] font-medium text-sm">
                {formatCurrency(car.min_increment)}
              </span>
            </div>
          </div>
        </Link>
      </div>
    </li>
  );
};

export default CarItem;
