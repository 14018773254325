import React, { useState } from "react";
import { Modal, Button, Input, Form, Steps, message, Checkbox } from "antd";
import { PhoneOutlined, LockOutlined, KeyOutlined } from "@ant-design/icons";
import AxiosController from "../../api/apiController";
import InputMask from "react-input-mask";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../../store/authSlice";
import { Link } from "react-router-dom";

const { Step } = Steps;

const LoginForm = () => {
  const [isLoginOpen, setLoginOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [accessToken, setAccessToken] = useState(null); // Geçici access_token
  const dispatch = useDispatch();
  const [rememberMe, setRememberMe] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const [form] = Form.useForm();

  const handleNext = () => setCurrentStep(prev => prev + 1);
  const handlePrev = () => setCurrentStep(prev => prev - 1);

  const handleLoginOpen = () => {
    setLoginOpen(true);
    setCurrentStep(0); // Her login açıldığında adım sıfırlansın
    form.resetFields();
  };
  const handleLoginClose = () => setLoginOpen(false);

  // Telefon ve şifre ile giriş API çağrısı
  const handleLoginSubmit = async (values) => {
    setIsLoading(true);
    try {
      // Telefon numarasındaki boşlukları kaldırıyoruz.
      const cleanedPhone = values.phone.replace(/\s+/g, '');
      // Diğer değerlerle birlikte temizlenmiş telefon numarasını gönderiyoruz.
      const requestData = { ...values, phone: cleanedPhone };
  
      const response = await AxiosController.post("/v1/auth/login", requestData);
      if (response?.data?.access_token) {
        setAccessToken(response.data.access_token);
        message.success("OTP gönderildi. Lütfen telefonunuza gelen OTP kodunu girin.");
        handleNext();
      } else {
        message.error("Beklenmedik bir hata oluştu, lütfen daha sonra tekrar deneyin.");
      }
    } catch (error) {
      console.error("Login error:", error);
      message.error("Giriş başarısız. Lütfen bilgilerinizi kontrol edin.");
    } finally {
      setIsLoading(false);
    }
  };
  

  // OTP doğrulama API çağrısı
  const handleOtpValidation = async (values) => {
    setIsLoading(true);
    try {
      const data = {
        login_token: accessToken,
        otp_code: values.otp,
      };
      const response = await AxiosController.post("/v1/auth/verify-otp", data);
      message.success("OTP doğrulandı, giriş yapılıyor.");

      // Giriş başarılı olduğunda Redux'a token'i kaydediyoruz
      dispatch(loginSuccess({ token: response.data.access_token, user: null }));
      setLoginOpen(false);
    } catch (error) {
      message.error("OTP doğrulama başarısız. Lütfen tekrar deneyin.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleRememberMeChange = (e) => {
    setRememberMe(e.target.checked);
  };

  return (
    <>
      <button
        onClick={handleLoginOpen}
        className="bg-[#FAFAFA] rounded-[56px] px-4 min-w-[100px] h-[40px] transition-all duration-300 ease-in-out hover:bg-[#FFEB00] hover:scale-105"
      >
        Giriş Yap
      </button>

      <Modal
        open={isLoginOpen}
        onCancel={handleLoginClose}
        footer={null}
        className="special-login"
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={currentStep === 0 ? handleLoginSubmit : handleOtpValidation}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              form.submit();
            }
          }}
        >
          <div className="flex flex-col md:flex-row items-center">
            {/* Yan taraftaki resim her durumda container'ı tamamen dolduracak */}
            <div className="w-full md:w-1/2 hidden md:flex h-full">
              <img
                src="/assets/img/login-ferrari.png"
                alt="login"
                className="w-full h-full object-cover rounded-l-[16px]"
              />
            </div>
            <div className="flex flex-col w-full md:w-1/2 px-8 py-8 md:py-4">
              {currentStep === 0 && (
                <>
                  <div className="flex flex-col">
                    <span className="text-[#09090B] font-semibold text-[20px]">
                      Giriş Yap
                    </span>
                    <span className="text-[#52525B] mt-[4px]">
                      Tekrar hoş geldiniz! Lütfen hesabınıza giriş yapın.
                    </span>
                  </div>
                  <Form.Item
                    name="phone"
                    label="Telefon"
                    rules={[{ required: true, message: "Lütfen telefon numaranızı girin!" }]}
                    className="mt-[12px]"
                  >
                    <InputMask mask="999 999 99 99" maskChar={""} placeholder="Telefon">
                      {(inputProps) => (
                        <Input
                          {...inputProps}
                          prefix={<PhoneOutlined />}
                          type="text"
                          className="rounded-[12px] border border-[#E4E4E7] p-[12px] -mt-1"
                        />
                      )}
                    </InputMask>
                  </Form.Item>

                  <Form.Item
                    name="password"
                    label="Şifre"
                    rules={[{ required: true, message: "Lütfen şifrenizi girin!" }]}
                    className="-mt-2.5"
                  >
                    <Input
                      prefix={<LockOutlined />}
                      type="password"
                      placeholder="Şifre"
                      className="rounded-[12px] border border-[#E4E4E7] p-[12px] -mt-1"
                    />
                  </Form.Item>
                  <Form.Item className="mb-[8px] -mt-4 self-end">
                    <Checkbox checked={rememberMe} onChange={handleRememberMeChange}>
                      Beni Hatırla
                    </Checkbox>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      htmlType="submit"
                      type="primary"
                      loading={isLoading}
                      disabled={isLoading}
                      className="w-full rounded-[12px] h-[40px] flex justify-center items-center gap-2 p-[12px] font-medium"
                    >
                      <span className="text-background">Giriş Yap</span>
                    </Button>
                  </Form.Item>
                  <Link to="/" className="underline text-[#09090B] self-center mt-3">
                    Şifreni mi unuttun?
                  </Link>
                </>
              )}
              {currentStep === 1 && (
                <>
                  <div className="flex flex-col">
                    <span className="text-[#09090B] font-semibold text-[20px]">OTP Gir</span>
                    <span className="text-[#52525B] mt-[4px]">
                      Güvenliğiniz için tek kullanımlık şifrenizi (OTP) girin.
                    </span>
                  </div>
                  <Form.Item
                    name="otp"
                    label="OTP"
                    rules={[{ required: true, message: "Lütfen OTP kodunu girin!" }]}
                    className="mt-[12px]"
                  >
                    <Input
                      prefix={<KeyOutlined />}
                      placeholder="OTP"
                      className="rounded-[12px] border border-[#E4E4E7] p-[12px] -mt-1"
                    />
                  </Form.Item>
                  <div className="flex flex-row gap-4">
                    <Button
                      htmlType="submit"
                      type="primary"
                      loading={isLoading}
                      disabled={isLoading}
                      className="w-full rounded-[12px] h-[40px] flex justify-center items-center gap-2 p-[12px] font-medium"
                    >
                      <span className="text-background">OTP Doğrula ve Giriş Yap</span>
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default LoginForm;
