import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import AuthModals from "./AuthModals";
import useAuth from "../hooks/useAuth";
import { useDispatch } from "react-redux";
import { logout } from "../store/authSlice";
import { Avatar, Button, Dropdown, Menu } from "antd";

const Navbar = () => {
  const { isAuthenticated, user } = useAuth();
  const dispatch = useDispatch();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const location = useLocation();

  const handleLogout = () => {
    dispatch(logout());
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  // Menü öğeleri için tanımlanmış diziler
  const navItems = [
    { path: "/ihaleler", label: "Araç Al" },
    { path: "/hakkimizda", label: "Hakkımızda" },
    { path: "/iletisim", label: "İletişim" },
  ];

  // Dropdown menü içeriği
  const userMenu = (
    <Menu>
      <Menu.Item key="profile">
        <Link to="/profilim">Profilim</Link>
      </Menu.Item>
      <Menu.Item key="account">
        <Link to="/hesabim">Hesabım</Link>
      </Menu.Item>
      <Menu.Item key="logout" onClick={handleLogout}>
        Çıkış Yap
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <div className="container mx-auto px-3 md:px-0">
        {/* Desktop Navbar */}
        <nav className="hidden md:flex justify-between items-center">
          <div className="flex items-center gap-[64px]">
            <Link to="/" className="py-2">
              <img src="/assets/icons/tt-logo.svg" alt="tt-logo" />
            </Link>
            <div className="flex items-center gap-x-[32px] text-[#09090B] min-h-max">
              {navItems.map((item) => (
                <Link
                  key={item.path}
                  to={item.path}
                  className="h-full relative gap-[22px] flex flex-col justify-between items-center whitespace-nowrap"
                >
                  <div className="h-[2px]"></div>
                  <span className="font-medium px-2">{item.label}</span>
                  {/* Alt çizgi (aktif link için animasyonlu) */}
                  <div
                    className={`w-full h-[2px] bg-background transition-all duration-300 ${location.pathname === item.path ? "opacity-100 scale-x-100" : "opacity-0 scale-x-0"
                      }`}
                  ></div>
                </Link>
              ))}
            </div>
          </div>
          {isAuthenticated ? (
            <div className="flex items-center gap-x-4 py-2">
              <Dropdown overlay={userMenu} trigger={["click"]}>
                <Button className="bg-[#F2F2F2] flex items-center gap-2 px-0 pr-4 rounded-[12px] h-[40px]">
                  <Avatar size={40} className="rounded-[12px] h-[40px]  w-[40px flex items-center justify-center ">
                    <div className="rounded-[12px] h-[40px] flex items-center justify-center ">
                      <span className="flex items-center justify-center text-primary">
                        {user?.full_name
                          ?.split(" ")
                          .map(word => word.charAt(0).toUpperCase())
                          .join("")}
                      </span>
                    </div>
                  </Avatar>
                  <div className="text-background capitalize">
                    {user?.full_name}
                  </div>
                </Button>
              </Dropdown>
            </div>
          ) : (
            <div>
              <AuthModals />
            </div>
          )}
        </nav>

        {/* Mobile Navbar */}
        <nav className="flex md:hidden justify-between items-center container mx-auto px-3 py-4">
          <Link to="/">
            <img src="/assets/icons/tt-logo.svg" alt="tt-logo" className="h-10" />
          </Link>
          <button onClick={toggleMobileMenu} className="text-2xl">
            <img src="assets/icons/menu.svg" alt="menu" />
          </button>
        </nav>

        {/* Mobile Menu Dropdown */}
        {isMobileMenuOpen && (
          <div className="md:hidden  w-full absolute top-16 left-0 w-full bg-white shadow-lg p-4 px-6 z-10">
            <div className="flex w-full flex-col items-start gap-4 text-sm font-medium text-[#09090B]">
              <Link to="/ihaleler" onClick={toggleMobileMenu}>Araç Al</Link>
              <Link to="/hakkimizda" onClick={toggleMobileMenu}>Hakkımızda</Link>
              {isAuthenticated && <Link to="/profile" onClick={toggleMobileMenu}>Profilim</Link>}
              <Link to="/iletisim" onClick={toggleMobileMenu}>İletişim</Link>
              {isAuthenticated ? (
                <div className="flex flex-col items-start gap-2">
                  <span>{user?.full_name}</span>
                  <Button onClick={() => { handleLogout(); toggleMobileMenu(); }} className="bg-red-500 text-white px-4 py-2 rounded">
                    Çıkış Yap
                  </Button>
                </div>
              ) : (
                <div className="w-full flex items-center justify-center">
                  <AuthModals />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="h-px w-full bg-gray-200 hidden lg:block"></div>
    </>
  );
};

export default Navbar;
