import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import AxiosCmsController from "../../api/apiControllerCms";
import Filtrevebanner from "./filtre-ve-banner";
import Markalar from "./markalar";
import Hizmetler from "./hizmetler";
import Tanitim from "./tanitim";
import Bolum1 from "./boeluem-1";
import Bolum2 from "./boeluem-2";
import Adimlar from "./adimlar";
import BlogBolum from "./blog-boeluemue";
import { Skeleton } from "antd";

const Home = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    AxiosCmsController.get('/api/home-page?populate=Sections.image,Sections.icon,Sections.backgroundImage,Sections.services.icon,Sections.promotions.icon,Sections.brands.image,Sections.steps.icon,Sections.blogs.image')
      .then((response) => {
        setData(response?.data?.Sections);
      })
      .catch((error) => {
        console.error("Error fetching about us data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const renderSection = (section) => {
    switch (section.__component) {
      case "home.filtre-ve-banner":
        return <Filtrevebanner key={'1'} data={section} />;
      case "home.markalar":
        return <Markalar key={'2'} data={section} />;
      case "home.hizmetler":
        return <Hizmetler key={'3'} data={section} />;
      case "home.tanitim":
        return <Tanitim key={"4"} data={section} />;
      case "home.boeluem-1":
        return <Bolum1 key={"5"} data={section} />;
      case "home.boeluem-2":
        return <Bolum2 key={"6"} data={section} />;
      case "home.adimlar":
        return <Adimlar key={"7"} data={section} />;
      case "home.blog-boeluemue":
        return <BlogBolum key={"8"} data={section} />;
      default:
        return null;
    }
  };

  return (
    <div className="lg:container lg:mx-auto">
      {loading ? (
        <Skeleton.Node
          className="custom-skeleton-img"
          style={{ height: "600px", borderRadius: "24px" }}
          active
        >
          <div className="flex h-full items-center justify-center w-full">
            <img
              src={'assets/icons/tt-logo.svg'}
              alt="fuel-icon"
              className="w-[150px] opacity-20"
            />
          </div>
        </Skeleton.Node>
      ) : (
        <div className="flex flex-col gap-12">
          {data?.map((section) => renderSection(section))}
        </div>
      )}

      {/* <div className="mt-[60px] lg:mt-[120px]">
        <h3 className="text-center font-semibold  text-[24px]">
          <span className="tracking-[10px] font-light">KASA</span> TİPLERİ
        </h3>
        <div className="grid grid-cols-2 lg:grid-cols-6 text-center gap-[12px] lg:gap-[12px] mt-[20px] lg:mt-[40px]">
          <div className="flex flex-col lg:border-r">
            <img src="/assets/icons/e-car.svg" alt="e-car" />
            <span className="mt-[24px] font-medium text-[#09090B]">
              Elektrikli
            </span>
          </div>
          <div className="flex flex-col lg:border-r">
            <img src="/assets/icons/sportback-car.svg" alt="sportback-car" />
            <span className="mt-[24px] font-medium text-[#09090B]">
              Sport Back
            </span>
          </div>
          <div className="flex flex-col lg:border-r">
            <img src="/assets/icons/sedan-car.svg" alt="sedan-car" />
            <span className="mt-[24px] font-medium text-[#09090B]">Sedan</span>
          </div>
          <div className="flex flex-col lg:border-r">
            <img src="/assets/icons/cabrio-car.svg" alt="cabrio-car" />
            <span className="mt-[24px] font-medium text-[#09090B]">
              Cabriolet
            </span>
          </div>
          <div className="flex flex-col lg:border-r">
            <img src="/assets/icons/sedan-car.svg" alt="sedan-car" />
            <span className="mt-[24px] font-medium text-[#09090B]">Sedan</span>
          </div>
          <div className="flex flex-col">
            <img src="/assets/icons/suv-car.svg" alt="suv-car" />
            <span className="mt-[24px] font-medium text-[#09090B]">SUV</span>
          </div>
        </div>
      </div> */}

      {/* <div className="mt-[60px] lg:mt-[120px]">
    <h4 className="text-center text-[#000000] font-bold text-[32px] lg:text-[40px] leading-normal lg:leading-relaxed">
      ÖNE ÇIKAN İLANLAR
    </h4>
    <div className="grid grid-cols-1 lg:grid-cols-4 mt-[24px] lg:mt-[48px] gap-y-[32px] lg:gap-x-[40px] lg:gap-y-[83px]">
      <div className="flex flex-col">
        <img src="/assets/img/grid-car-1.png" alt="grid-car-1" />
        <div className="mt-[24px] flex flex-col">
          <div className="flex justify-between">
            <h6
              className="text-[20px] font-medium"
              style={{ lineHeight: "27px" }}
            >
              Audi A4
            </h6>
            <div className="">
              <img src="/assets/icons/audi-icon.svg" alt="audi-icon" />
            </div>
          </div>
          <span
            className="text-light text-sm"
            style={{ lineHeight: "12px" }}
          >
            Sedan 40 TDI Quattro Advanced
          </span>
          <div className="mt-[20px] flex items-center gap-x-[32px]">
            <div className="flex items-center gap-2">
              <img src="/assets/icons/fuel.svg" alt="fuel-icon" />
              <span
                className="text-sm text-[#52525B]"
                style={{ lineHeight: "18px" }}
              >
                Dizel
              </span>
            </div>
            <div className="flex items-center gap-2">
              <img src="/assets/icons/gearbox.svg" alt="gearbox-icon" />
              <span
                className="text-sm text-[#52525B]"
                style={{ lineHeight: "18px" }}
              >
                Manuel
              </span>
            </div>
            <div className="flex items-center gap-2">
              <span
                className="text-sm text-[#52525B]"
                style={{ lineHeight: "18px" }}
              >
                2024
              </span>
            </div>
          </div>
          <div className="my-[16px] w-full h-px bg-[#18181C] bg-opacity-20"></div>
          <div className="flex flex-col">
            <span
              className="text-[#52525B] font-light text-sm"
              style={{ lineHeight: "18px" }}
            >
              Açılış Fiyatı
            </span>
            <div className="flex items-center gap-2 mt-[8px]">
              <span
                className="font-semibold text-[20px]"
                style={{ lineHeight: "25px" }}
              >
                7.490.000 TL
              </span>
              <span className="bg-[#FFEB00] py-[6px] px-[12px] rounded-[8px]">
                Açık Artırma
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <img src="/assets/img/lambo.png" alt="grid-car-2" />
        <div className="mt-[24px] flex flex-col">
          <div className="flex justify-between">
            <h6
              className="text-[20px] font-medium"
              style={{ lineHeight: "27px" }}
            >
              Lamborghini Invencible
            </h6>
            <div className="">
              <img
                src="/assets/icons/lamborghini-logo.svg"
                alt="lamborghini-icon"
              />
            </div>
          </div>
          <span
            className="text-light text-sm"
            style={{ lineHeight: "12px" }}
          >
            Sedan 40 TDI Quattro Advanced
          </span>
          <div className="mt-[20px] flex items-center gap-x-[32px]">
            <div className="flex items-center gap-2">
              <img src="/assets/icons/fuel.svg" alt="fuel-icon" />
              <span
                className="text-sm text-[#52525B]"
                style={{ lineHeight: "18px" }}
              >
                Benzin
              </span>
            </div>
            <div className="flex items-center gap-2">
              <img src="/assets/icons/gearbox.svg" alt="gearbox-icon" />
              <span
                className="text-sm text-[#52525B]"
                style={{ lineHeight: "18px" }}
              >
                Otomatik
              </span>
            </div>
            <div className="flex items-center gap-2">
              <span
                className="text-sm text-[#52525B]"
                style={{ lineHeight: "18px" }}
              >
                2024
              </span>
            </div>
          </div>
          <div className="my-[16px] w-full h-px bg-[#18181C] bg-opacity-20"></div>
          <div className="flex flex-col">
            <span
              className="text-[#52525B] font-light text-sm"
              style={{ lineHeight: "18px" }}
            >
              Açılış Fiyatı
            </span>
            <div className="flex items-center gap-2 mt-[8px]">
              <span
                className="font-semibold text-[20px]"
                style={{ lineHeight: "25px" }}
              >
                7.490.000 TL
              </span>
              <span className="bg-[#FFEB00] py-[6px] px-[12px] rounded-[8px]">
                Açık Artırma
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <img src="/assets/img/merco.png" alt="grid-car-3" />
        <div className="mt-[24px] flex flex-col">
          <div className="flex justify-between">
            <h6
              className="text-[20px] font-medium"
              style={{ lineHeight: "27px" }}
            >
              Mercedes Benz A200 Sedan
            </h6>
            <div className="">
              <img
                src="/assets/icons/mercedes-logo.svg"
                alt="mercedes-icon"
              />
            </div>
          </div>
          <span
            className="text-light text-sm"
            style={{ lineHeight: "12px" }}
          >
            Sedan 40 TDI Quattro Advanced
          </span>
          <div className="mt-[20px] flex items-center gap-x-[32px]">
            <div className="flex items-center gap-2">
              <img src="/assets/icons/fuel.svg" alt="fuel-icon" />
              <span
                className="text-sm text-[#52525B]"
                style={{ lineHeight: "18px" }}
              >
                Benzin
              </span>
            </div>
            <div className="flex items-center gap-2">
              <img src="/assets/icons/gearbox.svg" alt="gearbox-icon" />
              <span
                className="text-sm text-[#52525B]"
                style={{ lineHeight: "18px" }}
              >
                Otomatik
              </span>
            </div>
            <div className="flex items-center gap-2">
              <span
                className="text-sm text-[#52525B]"
                style={{ lineHeight: "18px" }}
              >
                2024
              </span>
            </div>
          </div>
          <div className="my-[16px] w-full h-px bg-[#18181C] bg-opacity-20"></div>
          <div className="flex flex-col">
            <span
              className="text-[#52525B] font-light text-sm"
              style={{ lineHeight: "18px" }}
            >
              Açılış Fiyatı
            </span>
            <div className="flex items-center gap-2 mt-[8px]">
              <span
                className="font-semibold text-[20px]"
                style={{ lineHeight: "25px" }}
              >
                7.490.000 TL
              </span>
              <span className="bg-[#FFEB00] py-[6px] px-[12px] rounded-[8px]">
                Açık Artırma
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <img src="/assets/img/lambo.png" alt="grid-car-1" />
        <div className="mt-[24px] flex flex-col">
          <div className="flex justify-between">
            <h6
              className="text-[20px] font-medium"
              style={{ lineHeight: "27px" }}
            >
              Lamborghini Invencible
            </h6>
            <div className="">
              <img
                src="/assets/icons/lamborghini-logo.svg"
                alt="lamborghini-icon"
              />
            </div>
          </div>
          <span
            className="text-light text-sm"
            style={{ lineHeight: "12px" }}
          >
            Sedan 40 TDI Quattro Advanced
          </span>
          <div className="mt-[20px] flex items-center gap-x-[32px]">
            <div className="flex items-center gap-2">
              <img src="/assets/icons/fuel.svg" alt="fuel-icon" />
              <span
                className="text-sm text-[#52525B]"
                style={{ lineHeight: "18px" }}
              >
                Benzin
              </span>
            </div>
            <div className="flex items-center gap-2">
              <img src="/assets/icons/gearbox.svg" alt="gearbox-icon" />
              <span
                className="text-sm text-[#52525B]"
                style={{ lineHeight: "18px" }}
              >
                Otomatik
              </span>
            </div>
            <div className="flex items-center gap-2">
              <span
                className="text-sm text-[#52525B]"
                style={{ lineHeight: "18px" }}
              >
                2024
              </span>
            </div>
          </div>
          <div className="my-[16px] w-full h-px bg-[#18181C] bg-opacity-20"></div>
          <div className="flex flex-col">
            <span
              className="text-[#52525B] font-light text-sm"
              style={{ lineHeight: "18px" }}
            >
              Açılış Fiyatı
            </span>
            <div className="flex items-center gap-2 mt-[8px]">
              <span
                className="font-semibold text-[20px]"
                style={{ lineHeight: "25px" }}
              >
                7.490.000 TL
              </span>
              <span className="bg-[#FFEB00] py-[6px] px-[12px] rounded-[8px]">
                Açık Artırma
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <img src="/assets/img/grid-car-1.png" alt="grid-car-1" />
        <div className="mt-[24px] flex flex-col">
          <div className="flex justify-between">
            <h6
              className="text-[20px] font-medium"
              style={{ lineHeight: "27px" }}
            >
              Audi A4
            </h6>
            <div className="">
              <img src="/assets/icons/audi-icon.svg" alt="audi-icon" />
            </div>
          </div>
          <span
            className="text-light text-sm"
            style={{ lineHeight: "12px" }}
          >
            Sedan 40 TDI Quattro Advanced
          </span>
          <div className="mt-[20px] flex items-center gap-x-[32px]">
            <div className="flex items-center gap-2">
              <img src="/assets/icons/fuel.svg" alt="fuel-icon" />
              <span
                className="text-sm text-[#52525B]"
                style={{ lineHeight: "18px" }}
              >
                Dizel
              </span>
            </div>
            <div className="flex items-center gap-2">
              <img src="/assets/icons/gearbox.svg" alt="gearbox-icon" />
              <span
                className="text-sm text-[#52525B]"
                style={{ lineHeight: "18px" }}
              >
                Manuel
              </span>
            </div>
            <div className="flex items-center gap-2">
              <span
                className="text-sm text-[#52525B]"
                style={{ lineHeight: "18px" }}
              >
                2024
              </span>
            </div>
          </div>
          <div className="my-[16px] w-full h-px bg-[#18181C] bg-opacity-20"></div>
          <div className="flex flex-col">
            <span
              className="text-[#52525B] font-light text-sm"
              style={{ lineHeight: "18px" }}
            >
              Açılış Fiyatı
            </span>
            <div className="flex items-center gap-2 mt-[8px]">
              <span
                className="font-semibold text-[20px]"
                style={{ lineHeight: "25px" }}
              >
                7.490.000 TL
              </span>
              <span className="bg-[#FFEB00] py-[6px] px-[12px] rounded-[8px]">
                Açık Artırma
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <img src="/assets/img/lambo.png" alt="grid-car-2" />
        <div className="mt-[24px] flex flex-col">
          <div className="flex justify-between">
            <h6
              className="text-[20px] font-medium"
              style={{ lineHeight: "27px" }}
            >
              Lamborghini Invencible
            </h6>
            <div className="">
              <img
                src="/assets/icons/lamborghini-logo.svg"
                alt="lamborghini-icon"
              />
            </div>
          </div>
          <span
            className="text-light text-sm"
            style={{ lineHeight: "12px" }}
          >
            Sedan 40 TDI Quattro Advanced
          </span>
          <div className="mt-[20px] flex items-center gap-x-[32px]">
            <div className="flex items-center gap-2">
              <img src="/assets/icons/fuel.svg" alt="fuel-icon" />
              <span
                className="text-sm text-[#52525B]"
                style={{ lineHeight: "18px" }}
              >
                Benzin
              </span>
            </div>
            <div className="flex items-center gap-2">
              <img src="/assets/icons/gearbox.svg" alt="gearbox-icon" />
              <span
                className="text-sm text-[#52525B]"
                style={{ lineHeight: "18px" }}
              >
                Otomatik
              </span>
            </div>
            <div className="flex items-center gap-2">
              <span
                className="text-sm text-[#52525B]"
                style={{ lineHeight: "18px" }}
              >
                2024
              </span>
            </div>
          </div>
          <div className="my-[16px] w-full h-px bg-[#18181C] bg-opacity-20"></div>
          <div className="flex flex-col">
            <span
              className="text-[#52525B] font-light text-sm"
              style={{ lineHeight: "18px" }}
            >
              Açılış Fiyatı
            </span>
            <div className="flex items-center gap-2 mt-[8px]">
              <span
                className="font-semibold text-[20px]"
                style={{ lineHeight: "25px" }}
              >
                7.490.000 TL
              </span>
              <span className="bg-[#FFEB00] py-[6px] px-[12px] rounded-[8px]">
                Açık Artırma
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <img src="/assets/img/merco.png" alt="grid-car-3" />
        <div className="mt-[24px] flex flex-col">
          <div className="flex justify-between">
            <h6
              className="text-[20px] font-medium"
              style={{ lineHeight: "27px" }}
            >
              Mercedes Benz A200 Sedan
            </h6>
            <div className="">
              <img
                src="/assets/icons/mercedes-logo.svg"
                alt="mercedes-icon"
              />
            </div>
          </div>
          <span
            className="text-light text-sm"
            style={{ lineHeight: "12px" }}
          >
            Sedan 40 TDI Quattro Advanced
          </span>
          <div className="mt-[20px] flex items-center gap-x-[32px]">
            <div className="flex items-center gap-2">
              <img src="/assets/icons/fuel.svg" alt="fuel-icon" />
              <span
                className="text-sm text-[#52525B]"
                style={{ lineHeight: "18px" }}
              >
                Benzin
              </span>
            </div>
            <div className="flex items-center gap-2">
              <img src="/assets/icons/gearbox.svg" alt="gearbox-icon" />
              <span
                className="text-sm text-[#52525B]"
                style={{ lineHeight: "18px" }}
              >
                Otomatik
              </span>
            </div>
            <div className="flex items-center gap-2">
              <span
                className="text-sm text-[#52525B]"
                style={{ lineHeight: "18px" }}
              >
                2024
              </span>
            </div>
          </div>
          <div className="my-[16px] w-full h-px bg-[#18181C] bg-opacity-20"></div>
          <div className="flex flex-col">
            <span
              className="text-[#52525B] font-light text-sm"
              style={{ lineHeight: "18px" }}
            >
              Açılış Fiyatı
            </span>
            <div className="flex items-center gap-2 mt-[8px]">
              <span
                className="font-semibold text-[20px]"
                style={{ lineHeight: "25px" }}
              >
                7.490.000 TL
              </span>
              <span className="bg-[#FFEB00] py-[6px] px-[12px] rounded-[8px]">
                Açık Artırma
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <img src="/assets/img/lambo.png" alt="grid-car-1" />
        <div className="mt-[24px] flex flex-col">
          <div className="flex justify-between">
            <h6
              className="text-[20px] font-medium"
              style={{ lineHeight: "27px" }}
            >
              Lamborghini Invencible
            </h6>
            <div className="">
              <img
                src="/assets/icons/lamborghini-logo.svg"
                alt="lamborghini-icon"
              />
            </div>
          </div>
          <span
            className="text-light text-sm"
            style={{ lineHeight: "12px" }}
          >
            Sedan 40 TDI Quattro Advanced
          </span>
          <div className="mt-[20px] flex items-center gap-x-[32px]">
            <div className="flex items-center gap-2">
              <img src="/assets/icons/fuel.svg" alt="fuel-icon" />
              <span
                className="text-sm text-[#52525B]"
                style={{ lineHeight: "18px" }}
              >
                Benzin
              </span>
            </div>
            <div className="flex items-center gap-2">
              <img src="/assets/icons/gearbox.svg" alt="gearbox-icon" />
              <span
                className="text-sm text-[#52525B]"
                style={{ lineHeight: "18px" }}
              >
                Otomatik
              </span>
            </div>
            <div className="flex items-center gap-2">
              <span
                className="text-sm text-[#52525B]"
                style={{ lineHeight: "18px" }}
              >
                2024
              </span>
            </div>
          </div>
          <div className="my-[16px] w-full h-px bg-[#18181C] bg-opacity-20"></div>
          <div className="flex flex-col">
            <span
              className="text-[#52525B] font-light text-sm"
              style={{ lineHeight: "18px" }}
            >
              Açılış Fiyatı
            </span>
            <div className="flex items-center gap-2 mt-[8px]">
              <span
                className="font-semibold text-[20px]"
                style={{ lineHeight: "25px" }}
              >
                7.490.000 TL
              </span>
              <span className="bg-[#FFEB00] py-[6px] px-[12px] rounded-[8px]">
                Açık Artırma
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> */}

      {/* <div className="mt-[60px] lg:mt-[120px]">
        <div className="grid grid-cols-2 lg:grid-cols-4 gap-7 lg:gap-0">
          <div className="flex flex-col-reverse lg:flex-col items-center">
            <span
              className="text-[32px] font-semibold"
              style={{ lineHeight: "40px" }}
            >
              660+
            </span>
            <div className="flex items-center gap-2 mb-2 lg:mb-0 lg:mt-[16px] ">
              <img
                src="/assets/icons/graduated-icon.svg"
                alt="oto-galeri-icon"
              />
              <span className="text-center" style={{ lineHeight: "24px" }}>
                Oto Galeriler
              </span>
            </div>
          </div>
          <div className="flex flex-col-reverse lg:flex-col items-center">
            <span
              className="text-[32px] font-semibold"
              style={{ lineHeight: "40px" }}
            >
              60.678
            </span>
            <div className="flex items-center gap-2  mb-2 lg:mb-0 lg:mt-[16px] ">
              <img src="/assets/icons/book-icon.svg" alt="satislar-icon" />
              <span className="text-center" style={{ lineHeight: "24px" }}>
                Satışlar
              </span>
            </div>
          </div>
          <div className="flex flex-col-reverse lg:flex-col items-center">
            <span
              className="text-[32px] font-semibold"
              style={{ lineHeight: "40px" }}
            >
              600.678
            </span>
            <div className="flex items-center gap-2 mb-2 lg:mb-0 lg:mt-[16px] ">
              <img
                src="/assets/icons/bank-card-icon.svg"
                alt="bank-card-icon"
              />
              <span className="text-center" style={{ lineHeight: "24px" }}>
                Aktif Kullanıcı
              </span>
            </div>
          </div>
          <div className="flex flex-col-reverse lg:flex-col items-center">
            <span
              className="text-[32px] font-semibold"
              style={{ lineHeight: "40px" }}
            >
              +9.786
            </span>
            <div className="flex items-center gap-2 mb-2 lg:mb-0 lg:mt-[16px]">
              <img src="/assets/icons/heart-icon.svg" alt="heart-icon" />
              <span className="text-center" style={{ lineHeight: "24px" }}>
                Party lasagna
              </span>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="mt-[60px] lg:mt-[120px]">
        <div className="flex items-center gap-2 justify-center">
          <img
            src="/assets/icons/message-icon.svg"
            alt="message-icon"
            className=""
          />
          <h4 className="font-semibold text-[32px] lg:mt-5 leading-normal">
            Müşterilerimiz Neler Düşünüyor?
          </h4>
        </div>
        <div className="grid lg:grid-cols-3 mt-[20px] gap-y-5 lg:gap-y-0 lg:mt-[40px] lg:gap-x-3">
          <div className="rounded-[24px] p-[16px]  lg:p-[32px] w-[365px] lg:w-[400px] 2xl:w-[426px] flex flex-col border border-[#E8EAED]">
            <div className="w-[96px]">
              <img src="/assets/icons/fivestar-icon.svg" alt="fivestar-icon" />
            </div>
            <h6
              className="mt-[16px] lg:mt-[32px] font-semibold"
              style={{ lineHeight: "20px" }}
            >
              Philly extra Chicago bell
            </h6>
            <span
              className="text-[#27272A] text-sm font-light max-w-[362px] mt-[8px] lg:mt-[16px]"
              style={{ lineHeight: "21px" }}
            >
              String Chicago sautéed peppers Philly tomatoes tomato Aussie burnt
              lovers. Garlic sausage pizza parmesan sautéed bbq and white.
              Philly extra Chicago bell chicken burnt wing large extra. Banana
              buffalo lasagna sautéed bell. Mushrooms beef pineapple pan steak
              onions dolor.
            </span>
            <div className="flex items-center gap-5 mt-[35px]">
              <img
                src="/assets/icons/tt-logo.svg"
                alt="img"
                className="w-[56px] h-[56px] rounded-full h-auto"
              />
              <div className="flex flex-col">
                <h6 className="font-semibold">ttihale</h6>
                <span className="mt-[8px] text-sm font-light text-[#52525B]">
                  Sr. Web Designer
                </span>
              </div>
            </div>
          </div>
          <div className="rounded-[24px] p-[16px]  lg:p-[32px] w-[365px] lg:w-[400px] 2xl:w-[426px] flex flex-col border border-[#E8EAED]">
            <div className="w-[96px]">
              <img src="/assets/icons/fivestar-icon.svg" alt="fivestar-icon" />
            </div>
            <h6
              className="mt-[16px] lg:mt-[32px] font-semibold"
              style={{ lineHeight: "20px" }}
            >
              Philly extra Chicago bell
            </h6>
            <span
              className="text-[#27272A] text-sm font-light max-w-[362px] mt-[8px] lg:mt-[16px]"
              style={{ lineHeight: "21px" }}
            >
              String Chicago sautéed peppers Philly tomatoes tomato Aussie burnt
              lovers. Garlic sausage pizza parmesan sautéed bbq and white.
              Philly extra Chicago bell chicken burnt wing large extra. Banana
              buffalo lasagna sautéed bell. Mushrooms beef pineapple pan steak
              onions dolor.
            </span>
            <div className="flex items-center gap-5 mt-[35px]">
              <img
                src="/assets/icons/tt-logo.svg"
                alt="img"
                className="w-[56px] h-[56px] rounded-full h-auto"
              />
              <div className="flex flex-col">
                <h6 className="font-semibold">ttihale</h6>
                <span className="mt-[8px] text-sm font-light text-[#52525B]">
                  Sr. Web Designer
                </span>
              </div>
            </div>
          </div>
          <div className="rounded-[24px] p-[16px]  lg:p-[32px] w-[365px] lg:w-[400px] 2xl:w-[426px] flex flex-col border border-[#E8EAED]">
            <div className="w-[96px]">
              <img src="/assets/icons/fivestar-icon.svg" alt="fivestar-icon" />
            </div>
            <h6
              className="mt-[16px] lg:mt-[32px] font-semibold"
              style={{ lineHeight: "20px" }}
            >
              Philly extra Chicago bell
            </h6>
            <span
              className="text-[#27272A] text-sm font-light max-w-[362px] mt-[8px] lg:mt-[16px]"
              style={{ lineHeight: "21px" }}
            >
              String Chicago sautéed peppers Philly tomatoes tomato Aussie burnt
              lovers. Garlic sausage pizza parmesan sautéed bbq and white.
              Philly extra Chicago bell chicken burnt wing large extra. Banana
              buffalo lasagna sautéed bell. Mushrooms beef pineapple pan steak
              onions dolor.
            </span>
            <div className="flex items-center gap-5 mt-[35px]">
              <img
                src="/assets/icons/tt-logo.svg"
                alt="img"
                className="w-[56px] h-[56px] rounded-full h-auto"
              />
              <div className="flex flex-col">
                <h6 className="font-semibold">ttihale</h6>
                <span className="mt-[8px] text-sm font-light text-[#52525B]">
                  Sr. Web Designer
                </span>
              </div>
            </div>
          </div>
        </div>
      </div> */}

    </div>
  );
};

export default Home;
