import React, { useState, useCallback, useEffect } from "react";
import { Spin, Pagination, message, Select, Drawer, Button } from "antd";
import qs from "qs";
import "../../css/FilterAccordion.css";
import { useLocation } from "react-router-dom";
import AxiosController from "../../api/apiController";
import CarItem from "./CarItem";
import FilterAccordion from "./FilterAccordion";
import CarItemSkeleton from "./CarItemSkeleton";

const { Option } = Select;

const Auctions = () => {
  const location = useLocation();

  // Filter states
  const [filterOptions, setFilterOptions] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({});

  // Car list, total results, loading
  const [cars, setCars] = useState([]);
  const [totalResults, setTotalResults] = useState(0);
  const [loading, setLoading] = useState(false);

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);

  // Sorting state
  const [sortOption, setSortOption] = useState("");

  // Mobile filter drawer state
  const [filterDrawerVisible, setFilterDrawerVisible] = useState(false);

  // Read query params on mount / location change
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const filters = {
      city_ids: params.get("city_ids") || undefined,
      brand_ids: params.get("brand_ids") || undefined,
      series_ids: params.get("series_ids") || undefined,
    };
    setSelectedFilters(filters);
  }, [location.search]);

  // Fetch filter data once on mount
  useEffect(() => {
    const fetchFilters = async () => {
      try {
        const response = await AxiosController.get("/v1/auction/car/filters/");
        if (response.data) {
          setFilterOptions(response.data);
        }
      } catch (error) {
        console.error("Filtre verileri hata:", error);
        message.error("Filtre verileri getirilirken bir hata oluştu.");
      }
    };
    fetchFilters();
  }, []);

  // Define fetchAuctions as a callback so it can be called explicitly
  const fetchAuctions = useCallback(async () => {
    setLoading(true);
    try {
      const params = {
        page: currentPage,
        page_size: pageSize,
      };

      // Add filters: if a filter is an array, pass it directly.
      Object.keys(selectedFilters).forEach((key) => {
        const value = selectedFilters[key];
        if (Array.isArray(value) && value.length > 0) {
          params[key] = value;
        } else if (value !== "" && value !== undefined) {
          params[key] = value;
        }
      });

      // Add sort option if available.
      if (sortOption) {
        const [sortBy, order] = sortOption.split("_");
        params["sort_by_" + sortBy] = order;
      }

      const response = await AxiosController.get("/v1/auction/car/auctions/", {
        params,
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: "repeat" }),
      });
      setCars(response.data || []);
      setTotalResults(response.total || 0);
    } catch (error) {
      console.error("Açık artırmalar çekilirken hata:", error);
      message.error("Açık artırmalar getirilirken bir hata oluştu.");
    } finally {
      setLoading(false);
    }
  }, [selectedFilters, currentPage, pageSize, sortOption]);

  // Automatically fetch auctions when page or sort changes (not on every filter change for mobile search)
  useEffect(() => {
    fetchAuctions();
  }, [currentPage, pageSize, sortOption, fetchAuctions]);

  // =========== FILTER HANDLERS ===========
  const handleSelectChange = (filterKey, itemId, checked) => {
    setSelectedFilters((prev) => {
      const currentVals = prev[filterKey] || [];
      if (checked) {
        return { ...prev, [filterKey]: [...currentVals, itemId] };
      } else {
        return { ...prev, [filterKey]: currentVals.filter((val) => val !== itemId) };
      }
    });
  };

  const handleRangeChange = (filterKey, newValues) => {
    const [minK, maxK] = filterKey.split(",");
    setSelectedFilters((prev) => ({
      ...prev,
      [minK]: newValues.min,
      [maxK]: newValues.max,
    }));
  };

  const handleResetFilter = (filterKey) => {
    if (filterKey.includes(",")) {
      const [minK, maxK] = filterKey.split(",");
      setSelectedFilters((prev) => {
        const newObj = { ...prev };
        delete newObj[minK];
        delete newObj[maxK];
        return newObj;
      });
    } else {
      setSelectedFilters((prev) => {
        const newObj = { ...prev };
        delete newObj[filterKey];
        return newObj;
      });
    }
  };

  const handleResetAll = () => {
    setSelectedFilters({});
    setSortOption("");
  };

  const handleSortChange = (value) => {
    setSortOption(value);
  };

  // onAra handler triggers the search explicitly.
  const handleAra = () => {
    fetchAuctions();
    // Close mobile drawer when search is triggered.
    setFilterDrawerVisible(false);
  };

  const filterCounts = filterOptions
  .map((filter) => {
    const selected = selectedFilters[filter.key];
    if (Array.isArray(selected)) {
      return selected.length;
    } else if (selected) {
      return 1;
    }
    return 0;
  })
  .filter((count) => count > 0);
const filterBadge = filterCounts.length ? ` (${filterCounts.join(", ")})` : "";

  return (
    <div className="container mx-auto">
      <Drawer
        title={"Filtre" + ` ${filterBadge}`}
        placement="left"
        onClose={() => setFilterDrawerVisible(false)}
        open={filterDrawerVisible}
        width="100%"
      >
        <div className="mb-4">
          <Select
            placeholder="Sıralama Seçiniz"
            value={sortOption || undefined}
            onChange={handleSortChange}
            style={{ width: "100%", height: 40 }}
            allowClear
          >
            <Option value="price_asc">Fiyata Göre Artan</Option>
            <Option value="price_desc">Fiyata Göre Azalan</Option>
            <Option value="year_asc">Yıla Göre Artan</Option>
            <Option value="year_desc">Yıla Göre Azalan</Option>
            <Option value="kilometres_asc">Kilometreye Göre Artan</Option>
            <Option value="kilometres_desc">Kilometreye Göre Azalan</Option>
            <Option value="start_date_asc">Başlangıç Tarihine Göre Artan</Option>
            <Option value="start_date_desc">Başlangıç Tarihine Göre Azalan</Option>
          </Select>
        </div>
        <FilterAccordion
          filterOptions={filterOptions}
          selectedFilters={selectedFilters}
          onSelectChange={handleSelectChange}
          onRangeChange={handleRangeChange}
          onResetFilter={handleResetFilter}
          onResetAll={handleResetAll}
          onAra={handleAra}
        />
      </Drawer>

      <div className="flex gap-8  w-full">
        {/* For Desktop: Filters shown on the left */}
        <div className="hidden lg:flex  gap-4 lg:flex-col  w-[280px] ">

          <div className="flex flex-row justify-between items-center">
            <span className="text-[#09090B] font-semibold text-lg">
              Filtre {filterBadge}
            </span>

            <div className="h-[36px]"></div>
          </div>
          <FilterAccordion
            filterOptions={filterOptions}
            selectedFilters={selectedFilters}
            onSelectChange={handleSelectChange}
            onRangeChange={handleRangeChange}
            onResetFilter={handleResetFilter}
            onResetAll={handleResetAll}
            onAra={handleAra}
          />
        </div>

        {/* Car List (Right) */}
        <div className="flex flex-col gap-4 w-full">

          <div className="flex flex-row justify-between items-center">
            <span className="text-[#09090B] font-semibold text-lg">
              {totalResults} Sonuç Bulundu
            </span>

            <div className="flex items-center gap-2.5 flex lg:hidden">
              <Button onClick={() => setFilterDrawerVisible(true)}>
                <img
                  src="/assets/icons/filter.svg"
                  alt="filter-icon"
                  className="w-5 h-5"
                />
                <span className="text-background">
                  Filtre {filterBadge}
                </span>
              </Button>
            </div>
            <div className="flex items-center gap-2.5 hidden lg:flex">
              {/* Desktop Sorting and other controls */}
              <Select
                placeholder="Sıralama Seçiniz"
                value={sortOption || undefined}
                onChange={handleSortChange}
                style={{ width: 250, height: 36 }}
                allowClear
              >
                <Option value="price_asc">Fiyata Göre Artan</Option>
                <Option value="price_desc">Fiyata Göre Azalan</Option>
                <Option value="year_asc">Yıla Göre Artan</Option>
                <Option value="year_desc">Yıla Göre Azalan</Option>
                <Option value="kilometres_asc">Kilometreye Göre Artan</Option>
                <Option value="kilometres_desc">Kilometreye Göre Azalan</Option>
                <Option value="start_date_asc">Başlangıç Tarihine Göre Artan</Option>
                <Option value="start_date_desc">Başlangıç Tarihine Göre Azalan</Option>
              </Select>
            </div>
          </div>

          {loading ? (
             <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
             {Array.from({ length: 9 }).map((_, idx) => (
               <CarItemSkeleton key={idx} />
             ))}
           </div>
          ) : (
            <>
              {cars.length === 0 ? (
                <div className="text-center my-10 text-gray-500">
                  Hiç araç bulunamadı.
                </div>
              ) : (
                <ul className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
                  {cars.map((car) => (
                    <CarItem key={car.id} car={car} />
                  ))}
                </ul>
              )}
            </>
          )}

          {/* Pagination */}
          <div className="flex justify-center my-5">
            <Pagination
              current={currentPage}
              pageSize={pageSize}
              total={totalResults}
              onChange={(page) => setCurrentPage(page)}
              showSizeChanger={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Auctions;
