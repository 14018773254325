import React, { useState, useEffect } from "react";
import AxiosController from "../../api/apiController";
import { message, Select, Button } from "antd";
import { useNavigate } from "react-router-dom";

const { Option } = Select;

const CarFilter = () => {
  const navigate = useNavigate();
  
  const [locations, setLocations] = useState([]);
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);

  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedModel, setSelectedModel] = useState("");

  // Fetch locations and brands on mount
  useEffect(() => {
    const fetchFilters = async () => {
      try {
        // Fetch locations
        const locationResponse = await AxiosController.get("/v1/home/select/location");
        if (locationResponse.data) {
          // assuming response.data.items holds the array of locations
          setLocations(locationResponse.data.items || []);
        }

        // Fetch brands
        const brandResponse = await AxiosController.get("/v1/home/select/brand");
        if (brandResponse.data) {
          setBrands(brandResponse.data.items || []);
        }
      } catch (error) {
        console.error("Filtre verileri getirilirken hata oluştu:", error);
        message.error("Filtre verileri getirilirken bir hata oluştu.");
      }
    };
    fetchFilters();
  }, []);

  // Fetch models whenever the selected brand changes
  useEffect(() => {
    const fetchModels = async () => {
      if (selectedBrand) {
        try {
          const modelResponse = await AxiosController.get("/v1/home/select/model", {
            params: { brand_id: selectedBrand },
          });
          if (modelResponse.data) {
            setModels(modelResponse.data.items || []);
          }
        } catch (error) {
          console.error("Modeller getirilirken hata oluştu:", error);
          message.error("Modeller getirilirken bir hata oluştu.");
        }
      } else {
        setModels([]);
      }
    };
    fetchModels();
  }, [selectedBrand]);

  // Form submit handler
  const handleSubmit = (event) => {
    event.preventDefault();
    const newParams = {};
    if (selectedLocation) {
      newParams["city_ids"] = selectedLocation;
    }
    if (selectedBrand) {
      newParams["brand_ids"] = selectedBrand;
    }
    if (selectedModel) {
      newParams["model_ids"] = selectedModel;
    }
    navigate({
      pathname: "/ihaleler",
      search: `?${new URLSearchParams(newParams).toString()}`,
    });
  };

  return (
    <form
      id="filter-select"
      onSubmit={handleSubmit}
      className="py-4 flex flex-col lg:flex-row lg:items-center rounded-[24px] gap-4 bg-white lg:w-full justify-between px-4"
    >
      {/* Location Select */}
      <div className="flex items-center gap-1.5 lg:gap-2.5 w-full">
        <img src="/assets/icons/location-mark-icon.svg" alt="location-icon" />
        <div className="w-full">
          <label className="block text-[#52525B] mb-1 text-sm font-light">
            Konum / Lokasyon
          </label>
          <Select
            placeholder="Seçiniz"
            value={selectedLocation || undefined}
            onChange={setSelectedLocation}
            className="w-full h-[40px]"
          >
            {locations.map((location) => (
              <Option key={location.id} value={location.id}>
                {location.name}
              </Option>
            ))}
          </Select>
        </div>
      </div>

      {/* Divider */}
      <div className="h-[48px] w-px bg-[#18181C] bg-opacity-20 mt-2.5 hidden lg:block"></div>
      <div className="w-full h-px bg-[#18181C] bg-opacity-20 my-2.5 lg:hidden"></div>

      {/* Brand Select */}
      <div className="flex items-center gap-1.5 lg:gap-2.5 w-full">
        <img src="/assets/icons/car-icon.svg" alt="car-icon" />
        <div className="w-full">
          <label className="block text-[#52525B] mb-1 text-sm font-light">
            Araç Markası
          </label>
          <Select
            placeholder="Seçiniz"
            value={selectedBrand || undefined}
            onChange={(value) => {
              setSelectedBrand(value);
              setSelectedModel(""); // reset model when brand changes
            }}
            className="w-full h-[40px]"
          >
            {brands.map((brand) => (
              <Option key={brand.id} value={brand.id}>
                {brand.name}
              </Option>
            ))}
          </Select>
        </div>
      </div>

      {/* Divider */}
      <div className="h-[48px] w-px bg-[#18181C] bg-opacity-20 mt-2.5 hidden lg:block"></div>
      <div className="w-full h-px bg-[#18181C] bg-opacity-20 my-2.5 lg:hidden"></div>

      {/* Model Select */}
      <div className="flex items-center gap-1.5 lg:gap-2.5 w-full">
        <img src="/assets/icons/car-icon.svg" alt="car-icon" />
        <div className="w-full">
          <label className="block text-[#52525B] mb-1 text-sm font-light">
            Araç Modeli
          </label>
          <Select
            placeholder="Seçiniz"
            value={selectedModel || undefined}
            onChange={setSelectedModel}
                  className="w-full h-[40px]"
            disabled={!selectedBrand}
          >
            {models.map((model) => (
              <Option key={model.id} value={model.id}>
                {model.name}
              </Option>
            ))}
          </Select>
        </div>
      </div>

      {/* Submit Button */}
      <div className="min-w-max bg-[#FFEB00] text-black font-medium px-4 py-2 flex justify-center rounded-[12px] items-center gap-1.5 lg:w-[192px] h-[64px]">
        <img src="/assets/icons/search-icon.svg" alt="search-icon" />
        <button type="submit">Hemen Bul</button>
      </div>
    </form>
  );
};

export default CarFilter;
