import React, { useState } from 'react';
import LoginForm from './general/LoginForm';
import RegisterForm from './general/SignupForm';



const AuthModals = () => {
  

  return (
      <div className="w-full flex justify-center items-center gap-x-[16px]">
        <LoginForm />
        <RegisterForm />
      </div>
  );
};

export default AuthModals;
