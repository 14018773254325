import React, { useState, useEffect } from "react";
import { Form, Input, Button, DatePicker, Tabs, message } from "antd";
import AxiosController from "../../api/apiController";
const { TextArea } = Input;
const { TabPane } = Tabs;

const Account = () => {
  const [activeKey, setActiveKey] = useState("profile");
  const [formProfile] = Form.useForm();
  const [formAddress] = Form.useForm();
  const [formCompany] = Form.useForm();
  const [formCommunication] = Form.useForm();
  const [authorizedBuyers, setAuthorizedBuyers] = useState([]);

  // Gerçek uygulamada bu değer kullanıcı verilerinden alınır.
  const isCorporate = true;

  useEffect(() => {
    // Profil verilerini çekiyoruz.
    AxiosController.get("/v1/user/profile")
      .then((res) => {
        formProfile.setFieldsValue(res.data.data);
      })
      .catch(() => {
        message.error("Profil bilgileri alınamadı");
      });

    // Adres verilerini çekiyoruz (ilk adres).
    AxiosController.get("/v1/user/addresses")
      .then((res) => {
        if (res.data.data && res.data.data.length > 0) {
          formAddress.setFieldsValue(res.data.data[0]);
        }
      })
      .catch(() => {
        message.error("Adres bilgileri alınamadı");
      });

    // Kurumsal kullanıcı ise şirket bilgilerini çekiyoruz.
    if (isCorporate) {
      AxiosController.get("/v1/user/company-information")
        .then((res) => {
          formCompany.setFieldsValue(res.data.data);
        })
        .catch(() => {
          message.error("Şirket bilgileri alınamadı");
        });
    }

    // İletişim tercihleri verilerini çekiyoruz.
    AxiosController.get("/v1/user/communication-preferences")
      .then((res) => {
        formCommunication.setFieldsValue(res.data.data);
      })
      .catch(() => {
        message.error("İletişim tercihleri alınamadı");
      });

    // Yetkili kişiler listesini çekiyoruz.
    AxiosController.get("/v1/user/authorized-buyers")
      .then((res) => {
        setAuthorizedBuyers(res.data.data);
      })
      .catch(() => {
        message.error("Yetkili kişiler alınamadı");
      });
  }, [formProfile, formAddress, formCompany, formCommunication, isCorporate]);

  const onFinishProfile = (values) => {
    AxiosController.put("/v1/user/profile", values)
      .then(() => {
        message.success("Profil başarıyla güncellendi");
      })
      .catch(() => {
        message.error("Profil güncellenemedi");
      });
  };

  const onFinishAddress = (values) => {
    AxiosController.put("/v1/user/addresses/1", values)
      .then(() => {
        message.success("Adres başarıyla güncellendi");
      })
      .catch(() => {
        message.error("Adres güncellenemedi");
      });
  };

  const onFinishCompany = (values) => {
    AxiosController.put("/v1/user/company-information", values)
      .then(() => {
        message.success("Şirket bilgileri başarıyla güncellendi");
      })
      .catch(() => {
        message.error("Şirket bilgileri güncellenemedi");
      });
  };

  const onFinishCommunication = (values) => {
    AxiosController.put("/v1/user/communication-preferences", values)
      .then(() => {
        message.success("İletişim tercihleri başarıyla güncellendi");
      })
      .catch(() => {
        message.error("İletişim tercihleri güncellenemedi");
      });
  };

  return (
    <div className="container mx-auto">
      <div className="border border-[#F4F4F5] rounded-[16px] p-[12px] mt-5 lg:mt-0 lg:p-[24px]">
        <Tabs activeKey={activeKey} onChange={setActiveKey}>
          {/* Profil Sekmesi (TCKN alanı kaldırıldı) */}
          <TabPane tab="Profil" key="profile">
            <span className="text-[#09090B] font-semibold mt-[28px] block">
              Kişisel Bilgiler
            </span>
            <div className="mt-[12px] lg:mt-[24px]">
              <Form
                form={formProfile}
                name="profileForm"
                layout="vertical"
                onFinish={onFinishProfile}
                autoComplete="off"
              >
                <div className="grid grid-cols-2 gap-x-[5px] lg:gap-x-[20px]">
                  <Form.Item
                    label="Adı"
                    name="firstName"
                    rules={[
                      { required: true, message: "Adınızı giriniz!" },
                      { pattern: /^[A-Za-z]+$/, message: "Adınız sadece harflerden oluşmalıdır!" },
                    ]}
                  >
                    <Input
                      placeholder="Adınız"
                      className="border-[#E4E4E7] rounded-[12px] p-[20px] w-full h-[52px] -mt-1.5"
                    />
                  </Form.Item>
                  <Form.Item
                    label="Soyadı"
                    name="lastName"
                    rules={[
                      { required: true, message: "Soyadınızı giriniz!" },
                      { pattern: /^[A-Za-z]+$/, message: "Soyadınız sadece harflerden oluşmalıdır!" },
                    ]}
                  >
                    <Input
                      placeholder="Soyadınız"
                      className="border-[#E4E4E7] rounded-[12px] p-[20px] w-full h-[52px] -mt-1.5"
                    />
                  </Form.Item>
                </div>
                <div className="grid lg:grid-cols-3 lg:gap-x-[20px]">
                  <Form.Item
                    label="Doğum Tarihi"
                    name="birthDate"
                    rules={[{ required: true, message: "Doğum tarihinizi giriniz!" }]}
                  >
                    <DatePicker
                      format="DD/MM/YYYY"
                      style={{ width: "100%" }}
                      className="border-[#E4E4E7] rounded-[12px] p-[20px] -mt-1.5"
                    />
                  </Form.Item>
                  {/* TCKN alanı kaldırıldı */}
                  <Form.Item
                    label="IBAN"
                    name="iban"
                    rules={[{ required: true, message: "IBAN giriniz!" }]}
                  >
                    <Input
                      placeholder="IBAN"
                      className="border-[#E4E4E7] rounded-[12px] p-[20px] -mt-1.5"
                    />
                  </Form.Item>
                </div>
                <Form.Item className="flex lg:justify-end">
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="bg-[#FFEB00] rounded-[12px] p-[4px] lg:p-[8px] text-[15px] font-semibold text-[#09090B] w-80 lg:w-[240px] h-[52px]"
                  >
                    Kaydet
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </TabPane>

          {/* Adres Sekmesi */}
          <TabPane tab="Adres" key="address">
            <span className="text-[#09090B] font-semibold mt-[28px] block">
              Adres Bilgileri
            </span>
            <div className="mt-[12px] lg:mt-[24px]">
              <Form
                form={formAddress}
                name="addressForm"
                layout="vertical"
                onFinish={onFinishAddress}
                autoComplete="off"
              >
                <div className="grid grid-cols-2 gap-x-[5px] lg:gap-x-[20px]">
                  <Form.Item
                    label="İl"
                    name="city"
                    rules={[
                      { required: true, message: "İl giriniz!" },
                      { pattern: /^[A-Za-z]+$/, message: "İl sadece harflerden oluşmalıdır!" },
                    ]}
                  >
                    <Input
                      placeholder="İl"
                      className="border-[#E4E4E7] rounded-[12px] p-[20px] w-full h-[52px] -mt-1.5"
                    />
                  </Form.Item>
                  <Form.Item
                    label="İlçe"
                    name="district"
                    rules={[
                      { required: true, message: "İlçe giriniz!" },
                      { pattern: /^[A-Za-z]+$/, message: "İlçe sadece harflerden oluşmalıdır!" },
                    ]}
                  >
                    <Input
                      placeholder="İlçe"
                      className="border-[#E4E4E7] rounded-[12px] p-[20px] w-full h-[52px] -mt-1.5"
                    />
                  </Form.Item>
                </div>
                <Form.Item
                  label="Adresiniz"
                  name="description"
                  rules={[{ required: true, message: "Lütfen bir adres giriniz!" }]}
                >
                  <TextArea
                    rows={2}
                    placeholder="Adresinizi buraya giriniz..."
                    className="border-[#E4E4E7] rounded-[12px] px-[10px] w-full -mt-1.5"
                  />
                </Form.Item>
                <Form.Item className="flex lg:justify-end">
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="bg-[#FFEB00] rounded-[12px] p-[4px] lg:p-[8px] text-[15px] font-semibold text-[#09090B] w-80 lg:w-[240px] h-[52px]"
                  >
                    Kaydet
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </TabPane>

          {/* Şirket Bilgileri Sekmesi (Kurumsal kullanıcılar için) */}
          {isCorporate && (
            <TabPane tab="Şirket Bilgileri" key="company">
              <span className="text-[#09090B] font-semibold mt-[28px] block">
                Şirket Bilgileri
              </span>
              <div className="mt-[12px] lg:mt-[24px]">
                <Form
                  form={formCompany}
                  name="companyForm"
                  layout="vertical"
                  onFinish={onFinishCompany}
                  autoComplete="off"
                >
                  <Form.Item
                    label="Şirket Adı"
                    name="companyName"
                    rules={[{ required: true, message: "Şirket adını giriniz!" }]}
                  >
                    <Input
                      placeholder="Şirket Adı"
                      className="border-[#E4E4E7] rounded-[12px] p-[20px] w-full h-[52px] -mt-1.5"
                    />
                  </Form.Item>
                  <Form.Item
                    label="Vergi Kimlik No"
                    name="taxId"
                    rules={[{ required: true, message: "Vergi kimlik numarasını giriniz!" }]}
                  >
                    <Input
                      placeholder="Vergi Kimlik No"
                      className="border-[#E4E4E7] rounded-[12px] p-[20px] w-full h-[52px] -mt-1.5"
                    />
                  </Form.Item>
                  <Form.Item
                    label="Şirket Adres"
                    name="companyAddress"
                    rules={[{ required: true, message: "Şirket adresini giriniz!" }]}
                  >
                    <TextArea
                      rows={2}
                      placeholder="Şirket adresinizi buraya giriniz..."
                      className="border-[#E4E4E7] rounded-[12px] px-[10px] w-full -mt-1.5"
                    />
                  </Form.Item>
                  <Form.Item className="flex lg:justify-end">
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="bg-[#FFEB00] rounded-[12px] p-[4px] lg:p-[8px] text-[15px] font-semibold text-[#09090B] w-80 lg:w-[240px] h-[52px]"
                    >
                      Kaydet
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </TabPane>
          )}

          {/* İletişim Tercihleri Sekmesi */}
          <TabPane tab="İletişim Tercihleri" key="communication">
            <span className="text-[#09090B] font-semibold mt-[28px] block">
              İletişim Tercihleri
            </span>
            <div className="mt-[12px] lg:mt-[24px]">
              <Form
                form={formCommunication}
                name="communicationForm"
                layout="vertical"
                onFinish={onFinishCommunication}
                autoComplete="off"
              >
                <Form.Item label="Sesli Arama" name="voice_call" valuePropName="checked">
                  <Input type="checkbox" />
                </Form.Item>
                <Form.Item label="SMS" name="sms" valuePropName="checked">
                  <Input type="checkbox" />
                </Form.Item>
                <Form.Item label="E-Posta" name="email" valuePropName="checked">
                  <Input type="checkbox" />
                </Form.Item>
                <Form.Item label="Bildirim" name="notification" valuePropName="checked">
                  <Input type="checkbox" />
                </Form.Item>
                <Form.Item className="flex lg:justify-end">
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="bg-[#FFEB00] rounded-[12px] p-[4px] lg:p-[8px] text-[15px] font-semibold text-[#09090B] w-80 lg:w-[240px] h-[52px]"
                  >
                    Kaydet
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </TabPane>

          {/* Yetkili Kişiler Sekmesi */}
          <TabPane tab="Yetkili Kişiler" key="authorized">
            <span className="text-[#09090B] font-semibold mt-[28px] block">
              Yetkili Kişiler
            </span>
            <div className="mt-[12px] lg:mt-[24px]">
              <Button
                type="primary"
                onClick={() => {
                  message.info("Yeni yetkili ekleme formu gösterilecek");
                }}
                className="mb-4"
              >
                Yeni Yetkili Ekle
              </Button>
              <div>
                {authorizedBuyers.length > 0 ? (
                  authorizedBuyers.map((buyer) => (
                    <div key={buyer.id} className="border p-2 mb-2 rounded">
                      <div>
                        {buyer.first_name} {buyer.last_name}
                      </div>
                      <div>TCKN: {buyer.tckn}</div>
                      <div>Telefon: {buyer.phone}</div>
                    </div>
                  ))
                ) : (
                  <div>Henüz yetkili kişi eklenmemiş.</div>
                )}
              </div>
            </div>
          </TabPane>

          {/* Durumlar Sekmesi */}
          <TabPane tab="Durumlar" key="statuses">
            <span className="text-[#09090B] font-semibold mt-[28px] block">
              Detaylı Durumlar
            </span>
            <div className="mt-[12px] lg:mt-[24px]">
              <Button
                type="primary"
                onClick={() => {
                  AxiosController.get("/v1/user/detailed-statuses?page=1&page_size=10")
                    .then((res) => {
                      console.log("Durumlar:", res.data.data);
                      message.success("Durumlar başarıyla alındı");
                    })
                    .catch(() => {
                      message.error("Durumlar alınamadı");
                    });
                }}
              >
                Durumları Getir
              </Button>
            </div>
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default Account;
