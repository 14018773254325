import React, { useState } from "react";
import { Select, Checkbox, Input, Slider, Affix, Button } from "antd";
import SmallHistogram from "./SmallHistogram";

const { Option } = Select;

const FilterAccordion = ({
    filterOptions = [],
    selectedFilters = {},
    onSelectChange,
    onRangeChange,
    onResetFilter,
    onResetAll,
    onAra,
}) => {
    // Helper: detect if the filter name includes "marka"
    const isMarkaFilter = (filter) =>
        filter.name.toLowerCase().includes("marka");

    // Helper: detect if the filter name includes "renk"
    const isRenkFilter = (filter) =>
        filter.name.toLowerCase().includes("renk");

    // Helper: for filters that should use a select picker with an "Ekle" trigger.
    // In this case: "Kasa Tipi", "Şehir", "Plaka Lokasyonu"
    const isEkleSelectFilter = (filter) => {
        const name = filter.name.toLowerCase();
        return name === "kasa tipi" || name === "şehir" || name === "plaka lokasyonu";
    };

    // For filters that should use direct checkboxes (not a select):
    // These include: "Yakıt Türü", "Vites Tipi", "Garanti Durumu", "Hasar Durumu"
    const shouldUseCheckboxLayout = (filter) =>
        filter.name === "Yakıt Türü" ||
        filter.name === "Vites Tipi" ||
        filter.name === "Garanti Durumu" ||
        filter.name === "Hasar Durumu";

    // Helper: decide if we should show a bar-chart placeholder for certain range filters
    const isBarChartRange = (filter) => {
        return (
            filter.type === "range" &&
            ["Kilometre", "Yıl", "Motor Gücü", "Motor Hacmi"].some((txt) =>
                filter.name.toLowerCase().includes(txt.toLowerCase())
            )
        );
    };

    // Handler for multi-select changes (for filters using the select picker)
    const handleMultiSelectChange = (filterKey, newValues) => {
        const oldValues = selectedFilters[filterKey] || [];
        const added = newValues.filter((val) => !oldValues.includes(val));
        const removed = oldValues.filter((val) => !newValues.includes(val));
        added.forEach((val) => onSelectChange(filterKey, val, true));
        removed.forEach((val) => onSelectChange(filterKey, val, false));
    };

    // Specific handler for "Ekle" select pickers.
    // We want a single selection action (i.e. not preserving the previous dropdown value).
    const handleSelectPickerChange = (filterKey, newValue) => {
        onSelectChange(filterKey, newValue, true);
        toggleSelectOpen(filterKey, false);
    };

    // Local state to control the open state of the select for filters that require an "Ekle" trigger.
    // We'll store a boolean for each filter key.
    const [selectOpen, setSelectOpen] = useState({});

    const toggleSelectOpen = (filterKey, open) => {
        setSelectOpen((prev) => ({ ...prev, [filterKey]: open }));
    };

    return (
        <div className="flex flex-col gap-4 md:w-[280px] text-sm w-full">
            {filterOptions.map((filter) => {
                // ------------------ SELECT FILTERS ------------------
                if (filter.type === "select") {
                    // If filter should use direct checkboxes:
                    if (shouldUseCheckboxLayout(filter)) {
                        const currentSelections = selectedFilters[filter.key] || [];
                        return (
                            <div key={filter.key} className="bg-white p-4 rounded-md border">
                                <div className="flex items-center justify-between">
                                    <h3 className="font-semibold text-base">{filter.name}</h3>
                                    <button
                                        onClick={() => onResetFilter(filter.key)}
                                        className="hover:bg-primary bg-transparent py-1 px-2 rounded-[8px] text-[#09090B] font-medium text-sm hover:underline"
                                    >
                                        <span className="">
                                            Sıfırla
                                        </span>
                                    </button>
                                </div>
                                <div className="mt-3 flex flex-wrap gap-2">
                                    {filter.items.map((item) => {
                                        const isChecked =
                                            (selectedFilters[filter.key] || []).includes(item.id);
                                        const hasColorValue = item.value;
                                        return (
                                            <label
                                                key={item.id}
                                                className="inline-flex items-center space-x-1 cursor-pointer"
                                            >
                                                <Checkbox
                                                    checked={isChecked}
                                                    onChange={(e) =>
                                                        onSelectChange(filter.key, item.id, e.target.checked)
                                                    }
                                                />
                                                {hasColorValue ? (
                                                    <div className="flex items-center gap-2">
                                                        <span
                                                            className="inline-block w-3 h-3 rounded-full"
                                                            style={{ backgroundColor: item.value }}
                                                        />
                                                        <span>{item.name}</span>
                                                    </div>
                                                ) : (
                                                    <span>{item.name}</span>
                                                )}
                                            </label>
                                        );
                                    })}
                                </div>
                            </div>
                        );
                    }

                    // For filters that should use a select picker with an "Ekle" trigger.
                    // This includes "Marka", "Renk", "Kasa Tipi", "Şehir", "Plaka Lokasyonu".
                    if (isMarkaFilter(filter) || isRenkFilter(filter) || isEkleSelectFilter(filter)) {
                        const currentSelections = selectedFilters[filter.key] || [];
                        return (
                            <div key={filter.key} className="bg-white p-4 rounded-md border">
                                {/* Header */}
                                <div className="flex items-center justify-between">
                                    <h3 className="font-semibold text-base">{filter.name}</h3>
                                    <button
                                        onClick={() => onResetFilter(filter.key)}
                                        className="hover:bg-primary bg-transparent py-1 px-2 rounded-[8px] text-[#09090B] font-medium text-sm hover:underline"
                                    >
                                        <span className="">
                                            Sıfırla
                                        </span>
                                    </button>
                                </div>
                                <div className="mt-3 space-y-2">
                                    {/* Show selected items as chips */}
                                    {filter.items
                                        .filter((item) => currentSelections.includes(item.id))
                                        .map((selectedItem) => (
                                            <div
                                                key={selectedItem.id}
                                                className="bg-[#F5F5F5] p-2 rounded flex items-center justify-between  border border-1.5 border-background"
                                            >
                                                <span className="text-sm">{selectedItem.name}</span>
                                                <button
                                                    className="text-xs text-gray-500 hover:text-black"
                                                    onClick={() =>
                                                        onSelectChange(filter.key, selectedItem.id, false)
                                                    }
                                                >
                                                    <img height={24} width={24} src="/assets/icons/closecube.svg" />
                                                </button>
                                            </div>
                                        ))}
                                    {/* Conditionally render the select picker only when open */}
                                    {selectOpen[filter.key] ? (
                                        <Select
                                            placeholder={`${filter.name} seçiniz`}
                                            style={{ width: "100%", height: 40 }}
                                            open={true}
                                            onChange={(newValue) =>
                                                handleSelectPickerChange(filter.key, newValue)
                                            }
                                            onDropdownVisibleChange={(open) =>
                                                toggleSelectOpen(filter.key, open)
                                            }
                                            value={undefined}
                                        >
                                            {filter.items
                                                .filter((item) => !currentSelections.includes(item.id))
                                                .map((option) => (
                                                    <Option key={option.id} value={option.id}>
                                                        {option.value ? (
                                                            <span>
                                                                <span
                                                                    className="inline-block w-3 h-3 rounded-full mr-1"
                                                                    style={{ backgroundColor: option.value }}
                                                                />
                                                                {option.name}
                                                            </span>
                                                        ) : (
                                                            option.name
                                                        )}
                                                    </Option>
                                                ))}
                                        </Select>
                                    ) : (
                                        <button
                                            className="hover:bg-primary flex items-center h-[40px] gap-1 bg-transparent py-1 px-2 rounded-[8px] text-[#09090B] font-medium text-sm hover:underline"
                                            onClick={() => toggleSelectOpen(filter.key, true)}
                                        >
                                            <img height={20} width={20} src="/assets/icons/pluscube.svg" />
                                            <span >
                                                {filter.name} Ekle
                                            </span>
                                        </button>
                                    )}
                                </div>
                            </div>
                        );
                    }

                    // For all other select filters, use the default multi-select without an "Ekle" trigger.
                    const currentSelectionsDefault = selectedFilters[filter.key] || [];
                    return (
                        <div key={filter.key} className="bg-white p-4 rounded-md border">
                            <div className="flex items-center justify-between">
                                <h3 className="font-semibold text-base">{filter.name}</h3>
                                <button
                                    onClick={() => onResetFilter(filter.key)}
                                    className="hover:bg-primary bg-transparent py-1 px-2 rounded-[8px] text-[#09090B] font-medium text-sm hover:underline"
                                >
                                    <span className="">
                                        Sıfırla
                                    </span>
                                </button>
                            </div>
                            <div className="mt-3">
                                <Select
                                    mode="multiple"
                                    allowClear
                                    placeholder={`${filter.name} seçiniz`}
                                    style={{ width: "100%" }}
                                    value={currentSelectionsDefault}
                                    onChange={(newValue) =>
                                        handleMultiSelectChange(filter.key, newValue)
                                    }
                                >
                                    {filter.items.map((item) => (
                                        <Option key={item.id} value={item.id}>
                                            {item.value ? (
                                                <span>
                                                    <span
                                                        className="inline-block w-3 h-3 rounded-full mr-1"
                                                        style={{ backgroundColor: item.value }}
                                                    />
                                                    {item.name}
                                                </span>
                                            ) : (
                                                item.name
                                            )}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                    );
                }

                // ------------------ RANGE FILTERS ------------------
                if (filter.type === "range") {
                    const [minKey, maxKey] = filter.key.split(",");
                    const minVal = selectedFilters[minKey] ?? filter.minRange;
                    const maxVal = selectedFilters[maxKey] ?? filter.maxRange;
                    if (isBarChartRange(filter)) {
                        // "Bar chart" range filter with histogram + slider + min/max inputs
                        return (
                          <div key={filter.key} className="bg-white p-4 rounded-md border">
                            <div className="flex items-center justify-between">
                              <h3 className="font-semibold text-base">{filter.name}</h3>
                              <button
                                onClick={() => onResetFilter(filter.key)}
                                className="hover:bg-primary bg-transparent py-1 px-2 rounded-[8px] text-[#09090B] font-medium text-sm hover:underline"
                              >
                                <span>Sıfırla</span>
                              </button>
                            </div>
                      
                            <div className="mt-3">
                              {/* Histogram */}
                              <div className="h-12 w-full rounded mb-2 flex items-center justify-center text-xs text-[#FF6838]">
                                <SmallHistogram />
                              </div>
                      
                              {/* Range Slider */}
                              <Slider
                                range
                                min={filter.minRange}
                                max={filter.maxRange}
                                value={[Number(minVal), Number(maxVal)]}
                                onChange={(vals) =>
                                  onRangeChange(filter.key, {
                                    min: vals[0],
                                    max: vals[1],
                                  })
                                }
                              />
                      
                              {/* Min/Max Inputs */}
                              <div className="mt-3 flex items-center gap-2">
                                <Input
                                  type="number"
                                  className="border p-1 rounded w-1/2"
                                  placeholder="Min"
                                  value={minVal}
                                  onChange={(e) =>
                                    onRangeChange(filter.key, {
                                      min: Number(e.target.value),
                                      max: Number(maxVal),
                                    })
                                  }
                                />
                                <Input
                                  type="number"
                                  className="border p-1 rounded w-1/2"
                                  placeholder="Max"
                                  value={maxVal}
                                  onChange={(e) =>
                                    onRangeChange(filter.key, {
                                      min: Number(minVal),
                                      max: Number(e.target.value),
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        );
                    } else {
                        return (
                            <div key={filter.key} className="bg-white p-4 rounded-md border">
                                <div className="flex items-center justify-between">
                                    <h3 className="font-semibold text-base">{filter.name}</h3>
                                    <button
                                        onClick={() => onResetFilter(filter.key)}
                                        className="hover:bg-primary bg-transparent py-1 px-2 rounded-[8px] text-[#09090B] font-medium text-sm hover:underline"
                                    >
                                        <span className="">
                                            Sıfırla
                                        </span>
                                    </button>
                                </div>
                                <div className="mt-3 flex items-center space-x-2">
                                    <Input
                                        type="number"
                                        className="border p-1 rounded w-1/2"
                                        placeholder="Min"
                                        value={minVal}
                                        onChange={(e) =>
                                            onRangeChange(filter.key, {
                                                min: Number(e.target.value),
                                                max: Number(maxVal),
                                            })
                                        }
                                    />
                                    <Input
                                        type="number"
                                        className="border p-1 rounded w-1/2"
                                        placeholder="Max"
                                        value={maxVal}
                                        onChange={(e) =>
                                            onRangeChange(filter.key, {
                                                min: Number(minVal),
                                                max: Number(e.target.value),
                                            })
                                        }
                                    />
                                </div>
                            </div>
                        );
                    }
                }

                return null;
            })}

            <Affix offsetBottom={24}>
                <div className="w-full md:w-[280px] flex justify-between gap-2 bg-white p-3 border rounded-md">
                    <Button
                        onClick={onResetAll}
                        className="w-full h-[40px] flex justify-center items-center gap-2 p-[12px] font-medium"
                    >
                        <span className="text-background">Tümünü Temizle</span>
                    </Button>
                    <Button
                        onClick={onAra}
                        type="primary"
                        className="w-full  h-[40px] flex justify-center items-center gap-2 p-[12px] font-medium"
                    >
                        <span className="text-background">Ara</span>
                    </Button>
                </div>
            </Affix>
        </div>
    );
};

export default FilterAccordion;
