import React, { useState } from "react";
import { Modal, Button, Input, Form, Select, message, Checkbox } from "antd";
import { PhoneOutlined, UserOutlined, MailOutlined, KeyOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import AxiosController from "../../api/apiController";
import InputMask from "react-input-mask";

const { Option } = Select;

const RegisterForm = () => {
  const [isSignupOpen, setSignupOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [registrationToken, setRegistrationToken] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  // Yerel olarak 1. ve 2. adım bilgilerini saklamak için
  const [formData, setFormData] = useState({});

  const handleNext = () => setCurrentStep(prev => prev + 1);
  const handlePrev = () => setCurrentStep(prev => prev - 1);

  const handleSignupOpen = () => {
    setSignupOpen(true);
    setCurrentStep(0);
    setFormData({});
    form.resetFields();
  };

  const handleSignupClose = () => setSignupOpen(false);

  // 1. Adım: Ad ve Soyad bilgilerini al
  const handleStepOneSubmit = (values) => {
    // Sadece ad ve soyad değerlerini saklıyoruz
    setFormData(values);
    handleNext(); // 2. adıma geç
    // İsterseniz form alanlarını resetleyip, 2. adıma özgü alanları gösterebilirsiniz.
    form.resetFields();
  };

  // 2. Adım: Telefon, e-posta, müşteri tipi ve üyelik sözleşmesi onayı
  const handleStepTwoSubmit = async (values) => {
    setIsLoading(true);
    try {
      // Önceki adım verilerini ve bu adım verilerini birleştiriyoruz
      const combinedData = { ...formData, ...values };
      // Telefon numarasındaki boşlukları kaldırıyoruz.
      if (combinedData.phone) {
        combinedData.phone = combinedData.phone.replace(/\s+/g, '');
      }
      // API'ye kayıt isteği gönderiyoruz
      const response = await AxiosController.post('/v1/auth/registarion/register', combinedData);
      setRegistrationToken(response.data.access_token);
      message.success('Kullanıcı bilgileri kaydedildi!');
      handleNext(); // OTP adımına geç
      form.resetFields();
    } catch (error) {
      console.error("User details submit error:", error);
      message.error('Kullanıcı bilgileri kaydedilemedi.');
    } finally {
      setIsLoading(false);
    }
  };

  // 3. Adım: OTP doğrulama
  const handleOtpSubmit = async (values) => {
    setIsLoading(true);
    try {
      const data = {
        registration_token: registrationToken,
        otp_code: values.otp_code,
      };
      await AxiosController.post('/v1/auth/registarion/verify-otp', data);
      message.success('OTP doğrulandı, kayıt tamamlandı!');
      setSignupOpen(false);
    } catch (error) {
      console.error("OTP submit error:", error);
      message.error('OTP doğrulaması başarısız.');
    } finally {
      setIsLoading(false);
    }
  };

  // Formun onFinish fonksiyonu adım bazlı yönlendiriliyor
  const onFinish = (values) => {
    if (currentStep === 0) {
      handleStepOneSubmit(values);
    } else if (currentStep === 1) {
      handleStepTwoSubmit(values);
    } else {
      handleOtpSubmit(values);
    }
  };

  return (
    <>
      <button
        onClick={handleSignupOpen}
        className="bg-[#FAFAFA] rounded-[56px] px-4 min-w-[100px] h-[40px] transition-all duration-300 ease-in-out hover:bg-[#FFEB00] hover:scale-105"
      >
        Üye Ol
      </button>

      <Modal
        open={isSignupOpen}
        onCancel={handleSignupClose}
        footer={null}
        className="special-login"
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          onKeyPress={(e) => {
            if (e.key === "Enter") form.submit();
          }}
        >
          <div className="flex flex-col md:flex-row items-center">
            {/* Yan taraftaki resim her durumda container'ı tamamen doldurur */}
            <div className="w-full md:w-1/2 hidden md:flex h-full">
              <img
                src="/assets/img/login-ferrari.png"
                alt="login"
                className="w-full h-full object-cover rounded-l-[16px]"
              />
            </div>
            <div className="flex flex-col w-full md:w-1/2 px-8 py-8 md:py-4">
              {currentStep === 0 && (
                <>
                  <div className="flex flex-col">
                    <span className="text-[#09090B] font-semibold text-[20px]">
                      Üye Ol
                    </span>
                    <span className="text-[#52525B] text-sm mt-[4px]">
                      Lütfen adınızı ve soyadınızı girin.
                    </span>
                  </div>
                  <Form.Item
                    name="first_name"
                    label="Adınız"
                    className="mt-[12px]"
                    rules={[{ required: true, message: 'Lütfen adınızı girin!' }]}
                  >
                    <Input
                      prefix={<UserOutlined />}
                      placeholder="Adınız"
                      className="rounded-[12px] border border-[#E4E4E7] p-[12px] -mt-1"
                    />
                  </Form.Item>
                  <Form.Item
                    name="last_name"
                    label="Soyadınız"
                    className="-mt-2.5"
                    rules={[{ required: true, message: 'Lütfen soyadınızı girin!' }]}
                  >
                    <Input
                      prefix={<UserOutlined />}
                      placeholder="Soyadınız"
                      className="rounded-[12px] border border-[#E4E4E7] p-[12px] -mt-1"
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      htmlType="submit"
                      type="primary"
                      loading={isLoading}
                      disabled={isLoading}
                      className="w-full rounded-[12px] h-[40px] flex justify-center items-center font-medium"
                    >

                      <span className="text-background">Devam Et</span>
                    </Button>
                  </Form.Item>
                </>
              )}
              {currentStep === 1 && (
                <>
                  <div className="flex flex-col">
                    <span className="text-[#09090B] font-semibold text-[20px]">
                      Üye Ol
                    </span>
                    <span className="text-[#52525B] text-sm mt-[4px]">
                      Lütfen kalan bilgileri girin.
                    </span>
                  </div>
                  <Form.Item
                    name="phone"
                    label="Telefon"
                    className="mt-[12px]"
                    rules={[{ required: true, message: 'Lütfen telefon numaranızı girin!' }]}
                  >
                    <InputMask mask="999 999 99 99" maskChar={""} placeholder="546 734 88 19">
                      {(inputProps) => (
                        <Input
                          {...inputProps}
                          prefix={<PhoneOutlined />}
                          type="text"
                          className="rounded-[12px] border border-[#E4E4E7] p-[12px] -mt-1"
                        />
                      )}
                    </InputMask>
                  </Form.Item>
                  <Form.Item
                    name="email"
                    label="E-Posta"
                    className="-mt-2.5"
                    rules={[{ required: true, message: 'Lütfen e‑posta adresinizi girin!' }]}
                  >
                    <Input
                      prefix={<MailOutlined />}
                      placeholder="E-Posta"
                      className="rounded-[12px] border border-[#E4E4E7] p-[12px] -mt-1"
                    />
                  </Form.Item>
                  <Form.Item
                    name="customer_type"
                    label="Müşteri Tipi"
                    className="-mt-2"
                    rules={[{ required: true, message: 'Lütfen müşteri tipini seçin!' }]}
                  >
                    <Select
                      style={{ height: 48 }}
                      placeholder="Müşteri Tipi Seçin"
                      className="-mt-1"
                    >
                      <Option value="individual">Bireysel</Option>
                      <Option value="corporate">Kurumsal</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item className="">
                    <Checkbox>
                      Üyelik Sözleşmesini onaylıyorum
                    </Checkbox>
                  </Form.Item>
                  <Form.Item className="-mt-2.5">
                    <Button
                      htmlType="submit"
                      type="primary"
                      loading={isLoading}
                      disabled={isLoading}
                      className="w-full rounded-[12px] h-[40px] flex justify-center items-center font-medium"
                    >
                      <span className="text-background">Bilgileri Kaydet</span>

                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="default"
                      onClick={handlePrev}
                      icon={<ArrowLeftOutlined className="text-background" />}
                    >

                      <span className="text-background">Geri</span>
                    </Button>
                  </Form.Item>
                </>
              )}
              {currentStep === 2 && (
                <>
                  <div className="flex flex-col">
                    <span className="text-[#09090B] font-semibold text-[20px]">
                      OTP Doğrulama
                    </span>
                    <span className="text-[#52525B] text-sm mt-[4px]">
                      Telefonunuza gönderilen OTP kodunu girin.
                    </span>
                  </div>
                  <Form.Item
                    name="otp_code"
                    label="OTP"
                    className="mt-[12px]"
                    rules={[{ required: true, message: 'Lütfen OTP kodunu girin!' }]}
                  >
                    <Input
                      prefix={<KeyOutlined />}
                      placeholder="OTP"
                      className="rounded-[12px] border border-[#E4E4E7] p-[12px]"
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      htmlType="submit"
                      type="primary"
                      loading={isLoading}
                      disabled={isLoading}
                      className="w-full rounded-[12px] h-[40px] flex justify-center items-center font-medium"
                    >

                      <span className="text-background">OTP Doğrula</span>
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="default"
                      onClick={handlePrev}
                      icon={<ArrowLeftOutlined className="text-background" />}
                    >
                      <span className="text-background">Geri</span>
                    </Button>
                  </Form.Item>
                </>
              )}
            </div>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default RegisterForm;
