// import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
// import axios from 'axios'

// const Footer = () => {
//   const [footerData, setFooterData] = useState(null)
//   useEffect(() => {
//     const fetchFooterData = async () => {
//       try {
//         const response = await axios.get('https://cms.ttihale.com/api/footer?populate=socialItems.icon&populate=linkItems.linksubitems&populate=sozlesmelers');
//         setFooterData(response.data.data);
//         console.log("Footer data:", response.data.data);
//       } catch (error) {
//         console.error("Error fetching footer data:", error);
//       }
//     };

//     fetchFooterData();
//   }, []);
//   return (
//     <footer className="bg-[#FFEB00] mt-[48px] lg:mt-[96px] lg:py-[48px]">
//       <div className="container mx-auto">
//         <div className="grid grid-cols-12  py-[24px] lg:py-0">
//           <div className="flex flex-col col-span-12 lg:col-span-4 ml-[20px]">
//             <Link to="/">
//               <img
//                 src="/assets/icons/tt-logo.svg"
//                 alt="tt-logo"
//                 className="w-[141px] h-[36px]"
//               />
//             </Link>
//             <span className="mt-[16px] text-sm font-light text-[#09090B] w-[309px] leading-normal">
//               String Chicago sautéed peppers Philly tomatoes tomato Aussie burnt
//               lovers. Garlic sausage pizza parmesan sautéed bbq and white.
//             </span>
//             <div className="mt-[16px] lg:mt-[32px] flex items-center gap-x-[16px]">
//               <img src="/assets/icons/facebook-icon.svg" alt="facebook-icon" />
//               <img src="/assets/icons/in-icon.svg" alt="linkedin-icon" />
//               <img src="/assets/icons/x-icon.svg" alt="x-icon" />
//               <img
//                 src="/assets/icons/instagram-icon.svg"
//                 alt="instagram-icon"
//               />
//             </div>
//           </div>
//           <div className="col-span-6 lg:col-span-2 ml-[20px] lg:ml-[40px] mt-[16px] lg:mt-[0px]">
//             <h6 className="text-[20px] font-semibold text-[#09090B]">
//               Lorem Ipsum
//             </h6>
//             <div className="flex flex-col mt-[24px] gap-4">
//               <Link to="/">Content 1</Link>
//               <Link to="/">Content 1</Link>
//               <Link to="/">Content 1</Link>
//               <Link to="/">Content 1</Link>
//             </div>
//           </div>
//           <div className="col-span-6 lg:col-span-2 ml-[20px] lg:ml-[40px] mt-[16px] lg:mt-[0px]">
//             <h6 className="text-[20px] font-semibold text-[#09090B]">
//               Lorem Ipsum
//             </h6>
//             <div className="flex flex-col mt-[24px] gap-4">
//               <Link to="/">Content 1</Link>
//               <Link to="/">Content 1</Link>
//               <Link to="/">Content 1</Link>
//               <Link to="/">Content 1</Link>
//             </div>
//           </div>
//           <div className="col-span-6 lg:col-span-2 ml-[20px] lg:ml-[40px] mt-[16px] lg:mt-[0px]">
//             <h6 className="text-[20px] font-semibold text-[#09090B]">
//               Lorem Ipsum
//             </h6>
//             <div className="flex flex-col mt-[24px] gap-2">
//               <Link to="/">Content 1</Link>
//               <Link to="/">Content 1</Link>
//               <Link to="/">Content 1</Link>
//               <Link to="/">Content 1</Link>
//             </div>
//           </div>
//           <div className="col-span-6 lg:col-span-2 ml-[20px] lg:ml-[40px] mt-[16px] lg:mt-[0px]">
//             <h6 className="text-[20px] font-semibold text-[#09090B]">
//               İletişim
//             </h6>
//             <div className="flex flex-col mt-[24px] gap-4">
//               <Link to="/" className="flex items-center gap-2">
//                 <img src="/assets/icons/mail-icon.svg" alt="mail-icon" />
//                 mail@info.com
//               </Link>
//               <Link to="/" className="flex items-center gap-2">
//                 <img src="/assets/icons/phone-icon.svg" alt="phone-icon" />
//                 850 000 00 00
//               </Link>
//               <Link to="/" className="flex items-center gap-2">
//                 <img
//                   src="/assets/icons/location-icon.svg"
//                   alt="location-icon"
//                 />
//                 Ataşehir / İstanbul
//               </Link>
//             </div>
//           </div>
//         </div>
//         <div className="mt-[20px] mb-[16px] lg:mt-[40px] lg:mb-[32px] h-px w-full bg-[#18181C] bg-opacity-20"></div>
//         <div className="grid grid-cols-1 gap-3 lg:gap-0 lg:flex justify-between text-center text-xs text-[#18181C] text-opacity-60">
//           <Link to="/">Üyelik Sözleşmesi</Link>
//           <Link to="/">Kurumsal Üyelik Sözleşmesi</Link>
//           <Link to="/">Çerez Aydınlatma Politikası</Link>
//           <Link to="/">Kullanım Koşulları</Link>
//           <Link to="/">Aydınlatma Metni</Link>
//           <Link to="/">KVKK politikası</Link>
//           <Link to="/">Bireysel Emanet Alım Sözleşmesi</Link>
//           <Link to="/">Kurumsal Emanet Alım Sözleşmesi</Link>
//           <Link to="/" className="">
//             Yönetim Bilgi Sistemleri Politikası
//           </Link>
//         </div>
//         <div className="my-[16px] lg:my-[32px] h-px w-full bg-[#18181C] bg-opacity-20"></div>
//         <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between">
//           <div className="flex items-center gap-2.5 ml-12 lg:ml-0 lg:hidden">
//             <img src="/assets/icons/appstore.svg" alt="appstore-icon" />
//             <img src="/assets/icons/playstore.svg" alt="playstore-icon" />
//           </div>
//           <div className="flex ml-12 lg:ml-0 mt-5 lg:mt-0 lg:items-center gap-x-[16px]">
//             <img src="/assets/icons/raven-logo.svg" alt="raven-logo" />
//             <div className="text-xs font-light text-[#09090B] w-44 leading-normal">
//               <span className="font-bold text-xs">TTihale.com,</span>alt yapı
//               gücünü Raven Soft'tan alır.
//             </div>
//           </div>
//           <div className="text-xs text-center lg:text-start mt-3 lg:mt-0 font-light text-[#1E1E1E]">
//             Copyright &copy; 2024
//           </div>
//           <div className="flex items-center gap-2.5 hidden lg:flex">
//             <img src="/assets/icons/appstore.svg" alt="appstore-icon" />
//             <img src="/assets/icons/playstore.svg" alt="playstore-icon" />
//           </div>
//         </div>
//       </div>
//     </footer>
//   );
// };

// export default Footer;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

const Footer = () => {
  const [footerData, setFooterData] = useState(null);

  useEffect(() => {
    const fetchFooterData = async () => {
      try {
        const response = await axios.get(
          "https://cms.ttihale.com/api/footer?populate=socialItems.icon&populate=linkItems.linksubitems&populate=sozlesmelers"
        );
        setFooterData(response.data.data);
        // console.log("Footer data:", response.data.data);
      } catch (error) {
        console.error("Error fetching footer data:", error);
      }
    };

    fetchFooterData();
  }, []);

  if (!footerData) return null;

  return (
    <footer className="bg-[#FFEB00] mt-[48px] lg:mt-[96px] lg:py-[48px]">
      <div className="container mx-auto">
        <div className="grid grid-cols-12 py-[24px] lg:py-0">
          <div className="flex flex-col col-span-12 lg:col-span-4 ml-[20px]">
            <Link to="/">
              <img
                src="/assets/icons/tt-logo.svg"
                alt="tt-logo"
                className="w-[141px] h-[36px]"
              />
            </Link>
            <span className="mt-[16px] text-sm font-light text-[#09090B] w-[309px] leading-normal">
              {footerData?.subDesc ||
                "Türkiye’nin En Yenilikçi İhale Platformu"}
            </span>
            <div className="mt-[16px] lg:mt-[32px] flex items-center gap-x-[16px]">
              {footerData?.socialItems?.map((item, index) => (
                <a
                  key={index}
                  href={item?.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={`https://cms.ttihale.com${item?.icon?.url}`}
                    alt={`${item?.title}-icon`}
                  />
                </a>
              ))}
            </div>
          </div>

          {/* Link Items Mapping */}
          {footerData?.linkItems?.map((linkItem, index) => (
            <div
              key={index}
              className="col-span-6 lg:col-span-2 ml-[20px] lg:ml-[40px] mt-[16px] lg:mt-[0px]"
            >
              <h6 className="text-[20px] font-semibold text-[#09090B]">
                {linkItem?.title}
              </h6>
              <div className="flex flex-col mt-[24px] gap-4">
                {linkItem?.linksubitems?.map((subItem, subIndex) => (
                  <Link key={subIndex} to={subItem?.link}>
                    {subItem?.title}
                  </Link>
                ))}
              </div>
            </div>
          ))}

          {/* Sözleşmeler Mapping */}
          <div className="col-span-6 lg:col-span-2 ml-[20px] lg:ml-[40px] mt-[16px] lg:mt-[0px]">
            <h6 className="text-[20px] font-semibold text-[#09090B]">
              Sözleşmeler
            </h6>
            <div className="flex flex-col mt-[24px] gap-4">
              {footerData?.sozlesmelers?.map((sozlesme, sozIndex) => (
                <Link key={sozIndex} to={`/sozlesmeler/${sozlesme?.documentId}`}>
                  {sozlesme?.title}
                </Link>
              ))}
            </div>
          </div>
        </div>

        <div className="mt-[20px] mb-[16px] lg:mt-[40px] lg:mb-[32px] h-px w-full bg-[#18181C] bg-opacity-20"></div>

        <div className="grid grid-cols-1 gap-3 lg:gap-0 lg:flex justify-between text-center text-xs text-[#18181C] text-opacity-60">
          {footerData?.sozlesmelers?.map((sozlesme, index) => (
            <Link key={index} to={`/sozlesmeler/${sozlesme?.documentId}`}>
              {sozlesme?.title}
            </Link>
          ))}
        </div>

        <div className="my-[16px] lg:my-[32px] h-px w-full bg-[#18181C] bg-opacity-20"></div>
        <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between">
          <div className="flex items-center gap-2.5 ml-12 lg:ml-0 lg:hidden">
            <img src="/assets/icons/appstore.svg" alt="appstore-icon" />
            <img src="/assets/icons/playstore.svg" alt="playstore-icon" />
          </div>
          <div className="flex ml-12 lg:ml-0 mt-5 lg:mt-0 lg:items-center gap-x-[16px]">
            <img src="/assets/icons/raven-logo.svg" alt="raven-logo" />
            <div className="text-xs font-light text-[#09090B] w-44 leading-normal">
              <span className="font-bold text-xs">TTihale.com,</span> altyapı
              gücünü Raven Soft'tan alır.
            </div>
          </div>
          <div className="text-xs text-center lg:text-start mt-3 lg:mt-0 font-light text-[#1E1E1E]">
            Copyright &copy; 2024
          </div>
          <div className="flex items-center gap-2.5 hidden lg:flex">
            <img src="/assets/icons/appstore.svg" alt="appstore-icon" />
            <img src="/assets/icons/playstore.svg" alt="playstore-icon" />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
